<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col size="12">
                <ng-container *ngIf="authService.doubleAuth">
                    <h1 class="ion-text-center">
                        <ion-text color="primary">Authentification à deux facteurs</ion-text>
                    </h1>
                    <p class="text-center my-2">
                        Vous avez activé l'authentification à double facteur.
                        <br />
                        Afin de vous connecter, veuillez saisir le code reçu par email.
                    </p>
                    <ion-input
                        label="Code reçu par email *"
                        class="text-center"
                        [(ngModel)]="authService.mfa"
                    ></ion-input>

                    <div class="my-6">
                        <ion-button expand="block" color="orange" (click)="checkMfa()">
                            Vérifier le code
                        </ion-button>
                    </div>
                </ng-container>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="!authService.doubleAuth">
            <ion-col size="12">
                <ion-grid style="margin: 0px 30px">
                    <ion-row>
                        <ion-col size="6">
                            <ion-button expand="block" color="dark" (click)="onAppleSignin()">
                                <ion-icon name="logo-apple"></ion-icon>
                            </ion-button>
                        </ion-col>
                        <ion-col size="6">
                            <ion-button expand="block" color="google" (click)="onGoogleSignin()">
                                <ion-icon name="logo-google"></ion-icon>
                            </ion-button>
                        </ion-col>
                        <!-- <ion-col size="4">
                            <ion-button
                                expand="block"
                                color="facebook"
                                (click)="onFacebookSignin()"
                            >
                                <ion-icon name="logo-facebook"></ion-icon>
                            </ion-button>
                        </ion-col> -->
                    </ion-row>
                </ion-grid>
                <form [formGroup]="loginForm" style="margin: 0px 30px">
                    <ion-label class="no-item-label">Adresse e-mail</ion-label>
                    <ion-input
                        required
                        type="email"
                        formControlName="email"
                        placeholder="Adresse e-mail"
                    ></ion-input>
                    <div
                        *ngIf="
                            loginForm.get('email').invalid &&
                            (loginForm.get('email').dirty || loginForm.get('email').touched)
                        "
                    >
                        <ion-text color="danger">L'adresse e-mail n'est pas valide.</ion-text>
                    </div>
                    <div class="password">
                        <ion-label class="no-item-label">Mot de passe</ion-label>
                        <ion-input
                            [type]="passwordType"
                            placeholder="Mot de passe"
                            required
                            formControlName="password"
                        >
                            <ion-button
                                fill="clear"
                                slot="end"
                                aria-label="Show/hide"
                                (click)="onShowPassword('pass')"
                            >
                                <ion-icon
                                    [name]="passwordType == 'password' ? 'eye' : 'eye-off'"
                                    slot="icon-only"
                                    color="medium"
                                ></ion-icon>
                            </ion-button>
                        </ion-input>
                    </div>
                    <div
                        *ngIf="
                            loginForm.get('password').invalid &&
                            (loginForm.get('password').dirty || loginForm.get('password').touched)
                        "
                    >
                        <ion-text color="danger">Le mot de passe est obligatoire.</ion-text>
                    </div>
                    <ion-button expand="block" color="orange" (click)="onSubmit()">
                        Se connecter
                    </ion-button>
                </form>
                <ion-button (click)="onPassword()" expand="block" fill="clear">
                    Mot de passe oublié ?
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="12">
                <ion-card color="orange" style="margin: 30px">
                    <ion-card-content>
                        <h1 class="ion-text-center">
                            {{
                                sessionSrv.onboarding
                                    ? '3 étapes pour créer votre événement'
                                    : "Rejoignez l'aventure Margaret dès aujourd'hui !"
                            }}
                        </h1>
                        <ion-grid *ngIf="sessionSrv.onboarding">
                            <ion-row>
                                <ion-col size="4" class="ion-text-center">
                                    <h1>Étape 1</h1>
                                    <div>Inscription en tant qu'utilisateur</div>
                                </ion-col>
                                <ion-col size="4" class="ion-text-center">
                                    <h1>Étape 2</h1>
                                    <div>Création de votre organisation en tant qu'annonceur</div>
                                </ion-col>
                                <ion-col size="4" class="ion-text-center">
                                    <h1>Étape 3</h1>
                                    <div>Publication de votre événement</div>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                        <ion-grid *ngIf="!sessionSrv.onboarding">
                            <ion-row>
                                <ion-col size="6">
                                    <ion-list lines="none">
                                        <ion-item>
                                            <strong>
                                                <ion-label>Pour un utilisateur :</ion-label>
                                            </strong>
                                        </ion-item>
                                        <ion-item>
                                            <ion-icon
                                                slot="start"
                                                name="checkmark-circle-outline"
                                                color="light"
                                            ></ion-icon>
                                            <ion-label>Mise en favoris</ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-icon
                                                slot="start"
                                                name="checkmark-circle-outline"
                                                color="light"
                                            ></ion-icon>
                                            <ion-label>Alertes en temps réel</ion-label>
                                        </ion-item>
                                    </ion-list>
                                </ion-col>
                                <ion-col size="6">
                                    <ion-list lines="none">
                                        <ion-item>
                                            <strong>
                                                <ion-label>Pour un annonceur :</ion-label>
                                            </strong>
                                        </ion-item>
                                        <ion-item>
                                            <ion-icon
                                                slot="start"
                                                name="checkmark-circle-outline"
                                                color="light"
                                            ></ion-icon>
                                            <ion-label>Gestion de vos organisations</ion-label>
                                        </ion-item>
                                        <ion-item>
                                            <ion-icon
                                                slot="start"
                                                name="checkmark-circle-outline"
                                                color="light"
                                            ></ion-icon>
                                            <ion-label>Publication d'événements</ion-label>
                                        </ion-item>
                                    </ion-list>
                                </ion-col>
                            </ion-row>
                        </ion-grid>

                        <ion-button (click)="onSignup()" expand="block" fill="solid" color="light">
                            S'inscrire
                        </ion-button>
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
