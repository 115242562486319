import { NgIf } from '@angular/common'
import { AfterViewInit, Component } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { Router } from '@angular/router'
import {
    SignInWithApple,
    SignInWithAppleOptions,
    SignInWithAppleResponse
} from '@capacitor-community/apple-sign-in'
import { FacebookLogin } from '@capacitor-community/facebook-login'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { IonicModule, ModalController, ToastController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth.service'
import { SessionService } from 'src/app/services/session.service'
import { ToastService } from 'src/app/services/toast.service'
import { UserService } from 'src/app/services/user.service'
import { environment } from '../../../../../../shared/environments/environment'
import { ProfileFormComponent } from '../../components/profile-form/profile-form.component'
import { AcceptCguModalComponent } from '../accept-cgu-modal/accept-cgu-modal.component'

@Component({
    selector: 'app-signup',
    templateUrl: 'signup.page.html',
    styleUrls: ['signup.page.scss'],
    standalone: true,
    imports: [IonicModule, NgIf, ProfileFormComponent, FormsModule]
})
export class SignupPage implements AfterViewInit {
    totpCode: string

    constructor(
        private authService: AuthService,
        private modalController: ModalController,
        public userSrv: UserService,
        public sessionSrv: SessionService,
        private toastCtrl: ToastController,
        private router: Router,
        private toastService: ToastService
    ) {}

    ngAfterViewInit(): void {
        this.totpCode = undefined
        GoogleAuth.initialize({
            clientId: '18216916625-t9pbo0qpi6lplcciseomejb8pic1ngon.apps.googleusercontent.com',
            scopes: ['profile', 'email'],
            grantOfflineAccess: true
        })
    }

    onClose() {
        this.modalController.dismiss()
    }

    onAppleSignin() {
        // TODO refactor with Login
        const options: SignInWithAppleOptions = {
            clientId: environment.appleClientID || '',
            redirectURI: environment.appleRedirectURI || '',
            scopes: 'email name',
            state: '19117219',
            nonce: 'nonce'
        }
        SignInWithApple.authorize(options)
            .then(async (result: SignInWithAppleResponse) => {
                this.authService.socialLogin('ac', result.response).subscribe(async () => {
                    if (!this.authService.currentUser.acceptedCGU) {
                        // CHECK refactoring ?
                        const modal = await this.modalController.create({
                            component: AcceptCguModalComponent,
                            cssClass: 'legal-use-modal'
                        })
                        await modal.present()
                        const data = await modal.onDidDismiss()
                        !data.data?.cgu
                            ? this.authService.logUserOut()
                            : this.modalController.dismiss()
                    }
                })
            })
            .catch(error => {})
    }

    onGoogleSignin() {
        GoogleAuth.signIn()
            .then(async (res: any) => {
                const body: any = {
                    identityToken: res.authentication.idToken
                }
                this.authService.socialLogin('go', body).subscribe(async () => {
                    if (!this.authService.currentUser.acceptedCGU) {
                        // CHECK refactoring ?
                        const modal = await this.modalController.create({
                            component: AcceptCguModalComponent,
                            cssClass: 'legal-use-modal'
                        })
                        await modal.present()
                        const data = await modal.onDidDismiss()
                        !data.data?.cgu
                            ? this.authService.logUserOut()
                            : this.modalController.dismiss()
                    }
                })
            })
            .catch(error => {})
    }

    async onFacebookSignin() {
        // TODO refactor with Login
        await FacebookLogin.initialize({ appId: '356656386476819' })
        const FACEBOOK_PERMISSIONS = ['email', 'public_profile', 'user_birthday']
        const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
        if (result.accessToken) {
            const resultProfile: any = await FacebookLogin.getProfile({
                fields: ['id', 'email', 'first_name', 'last_name', 'picture']
            })
            const res = {
                authToken: result.accessToken.token,
                email: resultProfile.email,
                firstName: resultProfile.first_name,
                id: resultProfile.id,
                lastName: resultProfile.last_name,
                photoUrl: resultProfile.picture.data.url
            }
            this.authService.socialLogin('fb', res).subscribe(async () => {
                if (!this.authService.currentUser.acceptedCGU) {
                    const modal = await this.modalController.create({
                        component: AcceptCguModalComponent,
                        cssClass: 'legal-use-modal'
                    })
                    await modal.present()
                    const data = await modal.onDidDismiss()
                    !data.data?.cgu ? this.authService.logUserOut() : this.modalController.dismiss()
                }
            })
        }
    }

    onLogin() {
        this.sessionSrv.authPage = 'omni'
    }

    checkCode() {
        this.authService
            .activateUser(this.sessionSrv.tempUserBeforeActivation.uniqueId, this.totpCode)
            .subscribe(
                async res => {
                    this.sessionSrv.authPage = 'omni'
                    this.sessionSrv.askingCode = false

                    // this.modalController.dismiss()
                    const fcmToken = await this.authService.getFcmToken()
                    this.authService.loginFormCredentials = {
                        username: this.sessionSrv.tempUserBeforeActivation.mail,
                        password: this.sessionSrv.tempPasswordBeforeActivation,
                        fcmToken
                    }
                    this.authService.newLogin(
                        this.sessionSrv.tempUserBeforeActivation.mail,
                        this.sessionSrv.tempPasswordBeforeActivation
                        // fcmToken
                    )
                    // .subscribe(
                    //     res => {
                    //         this.toastService.create(
                    //             'Votre compte a bien été créé. Vous pouvez maintenant vous connecter.',
                    //             3000
                    //         )
                    //         this.router.navigate(['/home'])
                    //     },
                    //     async error => {
                    //         const toast = await this.toastCtrl.create({
                    //             message: 'Veuillez saisir le code reçu par email',
                    //             duration: 3000,
                    //             color: 'primary'
                    //         })
                    //         await toast.present()
                    //     }
                    // )
                },
                async err => {
                    let message = ''
                    switch (err.status) {
                        case 400:
                            message = 'Ce code est invalide.'
                            break

                        case 409:
                            message = 'Ce code est expiré.'
                            break

                        default:
                            message = 'Une erreur est survenue.'
                            break
                    }
                    const toast = await this.toastCtrl.create({
                        message,
                        duration: 5000,
                        color: 'danger'
                    })
                    await toast.present()
                }
            )
    }
}
