import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs'
import { environment } from '../../../../shared/environments/environment'
import { MGCommons } from '../../../../shared/models/commons.model'

@Injectable({
    providedIn: 'root'
})
export class CommonsService {
    data: MGCommons

    constructor(private httpClient: HttpClient) {}

    get() {
        return this.httpClient.get<MGCommons>(`${environment.apiV3Url}/commons`).pipe(
            map(data => {
                data.categories.forEach(category => {
                    category.selected = false
                })
                data.actualites.forEach(evenement => {
                    evenement.selected = false
                })
                // const noCategory = {
                //     id: undefined,
                //     label: 'Tout',
                //     icon: 'fa-thin fa-empty-set',
                //     displayOrder: 0,
                //     createdAt: '',
                //     updatedAt: '',
                //     selected: true,
                //     key: 'noCategory',
                //     displayOnSearch: true
                // }
                // data.categories.unshift(noCategory)
                // data.actualites.unshift(noCategory)
                data.criterias.forEach(criteria => {
                    criteria.selected = false
                })
                this.data = data
                return data
            })
        )
    }

    getSearchCategories() {
        const clean = this.data.categories.filter(category => category.id > 0)
        return clean
    }

    getSearchActualites() {
        const clean = this.data.actualites.filter(actualite => actualite.id > 0)
        clean.unshift({
            id: 0,
            label: 'Aucune',
            icon: '',
            displayOrder: 0,
            createdAt: '',
            updatedAt: '',
            selected: true,
            key: 'noCategory',
            displayOnSearch: true
        })
        return clean
    }
}
