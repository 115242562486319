import { DatePipe, registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import localeFr from '@angular/common/locales/fr'
import { LOCALE_ID, NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { GoogleMapsModule } from '@angular/google-maps'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
// import { GooglePlaceModule } from 'ngx-google-places-autocomplete'
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete'
import { Clipboard } from '@angular/cdk/clipboard'
import { IConfig, provideNgxMask } from 'ngx-mask'
import { register } from 'swiper/element/bundle'
import { AppComponent } from './app.component'
import { HomeGuard } from './guards/home.guard'
import { IsAuthGuard } from './guards/is-auth.guard'
import { IsProGuard } from './guards/is-pro.guard'
import { StripeGuard } from './guards/stripe.guard'
import { TokenInterceptor } from './interceptors/token.interceptor'
import { EmbedVideoService } from './services/embed-video.service'

register()

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null

const routes: Routes = [
    {
        path: 'signup',
        loadComponent: () => import('./core/pages/signup/signup.page').then(m => m.SignupPage)
    },
    {
        path: 'logout',
        loadComponent: () =>
            import('./core/pages/auth/logout/logout.component').then(m => m.LogoutComponent)
    },
    {
        path: 'users/:id/activate/:key',
        loadComponent: () =>
            import('./core/pages/activation/activation.page').then(m => m.ActivationPage)
    },
    {
        path: 'password',
        loadComponent: () =>
            import('./core/pages/password-reset/password-reset.component').then(
                m => m.PasswordResetComponent
            )
    },
    {
        path: 'reset-password/:token',
        loadComponent: () =>
            import('./core/pages/password-new/password-new.component').then(
                m => m.PasswordNewComponent
            )
    },
    {
        path: 'home',
        title: 'MARGARET - TROUVEZ TOUTES VOS SORTIES EN HAUTS-DE-FRANCE',
        loadComponent: () => import('./core/pages/home/home.component').then(m => m.HomePage),
        canActivate: [HomeGuard]
    },
    {
        path: 'home/:token',
        canActivate: [HomeGuard],
        title: 'MARGARET - TROUVEZ TOUTES VOS SORTIES EN HAUTS-DE-FRANCE',
        loadComponent: () => import('./core/pages/home/home.component').then(m => m.HomePage)
    },
    {
        path: 'dashboard',
        loadComponent: () =>
            import('./core/pages/dashboard/dashboard.page').then(m => m.DashboardPage),
        canActivate: [HomeGuard]
    },
    {
        path: 'dashboard/devices',
        canActivate: [IsAuthGuard, StripeGuard],
        title: 'Appareils connectés',
        loadComponent: () =>
            import('./core/pages/devices/devices.component').then(m => m.DevicesComponent)
    },
    {
        path: 'dashboard/members',
        canActivate: [IsAuthGuard, StripeGuard],
        title: 'Gestion des membres',
        loadComponent: () =>
            import('./core/pages/members/members.component').then(m => m.MembersComponent)
    },
    {
        path: 'dashboard/organization/edit',
        canActivate: [IsAuthGuard, StripeGuard],
        title: `Gestion de l'organisation`,
        loadComponent: () =>
            import('./core/pages/organization-edit/organization-edit.page').then(
                m => m.OrganizationEditPage
            )
    },
    {
        path: 'dashboard/event',
        canActivate: [IsAuthGuard, StripeGuard],
        title: 'Nouvel événement',
        loadComponent: () =>
            import('./core/pages/event-new/event-new.component').then(m => m.EventNewComponent)
    },
    {
        path: 'mobile',
        loadComponent: () =>
            import('./core/pages/mobile/mobile.component').then(m => m.MobileComponent),
        canActivate: [HomeGuard]
    },
    {
        path: 'auth',
        loadComponent: () => import('./core/pages/auth/auth.component').then(m => m.AuthComponent),
        canActivate: [HomeGuard]
    },
    {
        path: 'contact',
        title: `CONTACTER L'ÉQUIPE MARGARET`,
        loadComponent: () =>
            import('./core/pages/contact/contact.component').then(m => m.ContactComponent)
    },
    {
        path: 'suggestions',
        loadComponent: () =>
            import('./core/pages/suggestions/suggestions.component').then(
                m => m.SuggestionsComponent
            )
    },
    {
        path: 'faq',
        loadComponent: () => import('./core/pages/faq/faq.component').then(m => m.FaqComponent)
    },
    {
        path: 'about',
        title: 'À PROPOS DE MARGARET...',
        loadComponent: () => import('./core/pages/about/about.page').then(m => m.AboutComponent)
    },
    {
        path: 'legal',
        title: 'MENTIONS LÉGALES',
        loadComponent: () => import('./core/pages/legal/legal.page').then(m => m.LegalPage)
    },
    {
        path: 'cgu',
        title: "CONDITIONS GÉNÉRALES D'UTILISATION",
        loadComponent: () => import('./core/pages/cgu/cgu.page').then(m => m.CguPage)
    },
    {
        path: 'pricing',
        title: 'TARIFICATION MARGARET',
        loadComponent: () =>
            import('./core/pages/pricing/pricing.component').then(m => m.PricingComponent)
    },
    {
        path: 'confidentialité',
        loadComponent: () =>
            import('./core/pages/confidentiality/confidentiality.page').then(
                m => m.ConfidentialityPage
            )
    },
    {
        path: 'subscription',
        canActivate: [],
        title: 'Abonnements Margaret',
        loadComponent: () =>
            import('./core/pages/subscription/subscription.component').then(
                m => m.SubscriptionComponent
            )
    },
    // {
    //     path: 'subscription/management',
    //     canActivate: [StripeGuard],
    //     title: "Gestion de l'abonnement",
    //     loadComponent: () =>
    //         import('./core/pages/subscription-management/subscription-management.component').then(
    //             m => m.SubscriptionManagementComponent
    //         )
    // },
    {
        path: 'subscription/success',
        title: 'Abonnement réussi',
        loadComponent: () =>
            import('./core/pages/payment-success/payment-success.component').then(
                m => m.PaymentSuccessComponent
            )
    },
    {
        path: 'subscription/failure',
        title: "L'abonnement a échoué",
        loadComponent: () =>
            import('./core/pages/payment-failure/payment-failure.component').then(
                m => m.PaymentFailureComponent
            )
    },
    {
        path: 'eventpayment/success',
        title: 'Paiement réussi',
        loadComponent: () =>
            import('./core/pages/payment-success/payment-success.component').then(
                m => m.PaymentSuccessComponent
            )
    },
    {
        path: 'eventpayment/failure',
        title: 'Le paiement a échoué',
        loadComponent: () =>
            import('./core/pages/payment-failure/payment-failure.component').then(
                m => m.PaymentFailureComponent
            )
    },
    {
        path: 'pack/:packName',
        canActivate: [StripeGuard],
        title: 'Pack Margaret',
        loadComponent: () =>
            import('./core/pages/subscription/pack/pack.component').then(m => m.PackComponent)
    },
    {
        path: 'organizations/margaret',
        loadComponent: () =>
            import('./core/pages/organization-margaret/organization-margaret.component').then(
                m => m.OrganizationMargaretComponent
            )
    },
    {
        path: 'organizations/new',
        canActivate: [IsAuthGuard, StripeGuard],
        title: 'CRÉER MON ORGANISATION',
        loadComponent: () =>
            import('./core/pages/organization-edit/organization-edit.page').then(
                m => m.OrganizationEditPage
            )
    },
    {
        path: 'organizations/edit',
        canActivate: [IsAuthGuard, StripeGuard],
        title: `ÉDITER MON ORGANISATION`,
        loadComponent: () =>
            import('./core/pages/organization-edit/organization-edit.page').then(
                m => m.OrganizationEditPage
            )
    },
    {
        path: 'organizations/:id',
        loadComponent: () =>
            import('./core/pages/organization-detail/organization-detail.page').then(
                m => m.OrganizationDetailPage
            )
    },
    {
        path: 'organizations/:id/mobile',
        loadComponent: () =>
            import(
                './core/pages/organization-detail-mobile/organization-detail-mobile.component'
            ).then(m => m.OrganizationDetailMobileComponent)
    },
    {
        path: 'search-mobile',
        loadComponent: () =>
            import('./core/pages/alert-new/alert-new.page').then(m => m.AlertNewPage)
    },
    {
        path: 'event',
        canActivate: [IsAuthGuard, /*FreeLimitReachedGuard,*/ StripeGuard],
        title: 'GÉRER MON ÉVÉNEMENT',
        loadComponent: () =>
            import('./core/pages/event/event.component').then(m => m.EventComponent)
    },
    {
        path: 'events',
        title: 'RECHERCHE DES MEILLEURES SORTIES',
        loadComponent: () =>
            import('./core/pages/event-list/event-list.page').then(m => m.EventListPage)
    },
    {
        path: 'events/:uniqueId',
        loadComponent: () =>
            import('./core/pages/event-detail/event-detail.page').then(m => m.EventDetailPage)
    },
    {
        path: 'events/edit/:id',
        // canActivate: [StripeGuard],
        title: "Édition de l'évènement",
        loadComponent: () =>
            import('./core/pages/event/event.component').then(m => m.EventComponent)
    },
    {
        path: 'events/:uniqueId/mobile',
        loadComponent: () =>
            import('./core/pages/event-detail-mobile/event-detail-mobile.component').then(
                m => m.EventDetailMobileComponent
            )
    },
    {
        path: 'profile/edit',
        title: 'GÉRER MON PROFIL',
        canActivate: [IsAuthGuard],
        loadComponent: () =>
            import('./core/pages/profile-edit/profile-edit.page').then(m => m.ProfileEditPage)
    },
    {
        path: 'alerts',
        title: 'MES ALERTES',
        canActivate: [IsProGuard, IsAuthGuard],
        loadComponent: () =>
            import('./core/pages/alert-list/alert-list.page').then(m => m.AlertListPage)
    },
    {
        path: 'favorites',
        title: 'MES FAVORIS',
        canActivate: [IsProGuard, IsAuthGuard],
        loadComponent: () =>
            import('./core/pages/favorite-list/favorite-list.page').then(m => m.FavoriteListPage)
    },
    { path: '**', redirectTo: 'home' }
]

registerLocaleData(localeFr, 'fr')

@NgModule({
    declarations: [AppComponent],
    imports: [
        NgxGpAutocompleteModule.forRoot({
            loaderOptions: {
                apiKey: 'AIzaSyCvggQ2UGkRUP56wAqijAJTuOK0uUgm9u8',
                libraries: ['places']
            }
        }),
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        RouterModule.forRoot(routes, {
            // preloadingStrategy: PreloadAllModules,
            initialNavigation: 'enabledBlocking'
        }),
        IonicModule.forRoot({ backButtonText: '', swipeBackEnabled: false }),
        HttpClientModule,
        ReactiveFormsModule,
        GoogleMapsModule,
        // GooglePlaceModule,
        FontAwesomeModule
        // NgxMaskModule.forRoot()
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        DatePipe,
        EmbedVideoService,
        Clipboard,
        provideNgxMask()
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        library.add()
    }
}
