import { isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import jwt_decode from 'jwt-decode'
import { AccessTokenData } from '../../../../shared/models/access-token-data.model'
import { CurrentUser } from '../../../../shared/models/auth/current-user.model'

@Injectable({ providedIn: 'root' })
export class JwtService {
    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any
    ) {}

    decode(accessToken): AccessTokenData {
        const accessTokenData = jwt_decode(accessToken) as AccessTokenData
        return accessTokenData
    }

    clearAuthData() {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('token')
            localStorage.removeItem('expiration')
            localStorage.removeItem('user')
        }
    }

    getAuthData(): { token: string; expirationDate: Date; user: CurrentUser } {
        if (isPlatformBrowser(this.platformId)) {
            const storageToken = localStorage.getItem('token')
            const expiration = localStorage.getItem('expiration')
            const userStr = localStorage.getItem('user')

            if (!storageToken || !expiration || !userStr) {
                return
            }

            return {
                token: storageToken,
                expirationDate: new Date(expiration),
                user: JSON.parse(userStr)
            }
        }
    }

    saveAuthData(token: string, expirationDate: Date, user: CurrentUser) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('token', token)
            localStorage.setItem('expiration', expirationDate.toISOString())
            this.saveCurrentUser(user)
        }
    }

    saveCurrentUser(currentUser: CurrentUser) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('user', JSON.stringify(currentUser))
        }
    }
}
