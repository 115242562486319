import { isPlatformBrowser } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { Observable, TimeoutError } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../shared/environments/environment'
import { BuildCreateEventInput } from '../../../../shared/models/events/build-create-event-input.model'
import { CreateEventInput } from '../../../../shared/models/events/create-event-input.model'
import { GetEventsData } from '../../../../shared/models/events/get-events-data.model'
import { MGEvent, MargaretEvent } from '../../../../shared/models/events/margaret-event.model'
import { AuthService } from './auth.service'
import { ToastService } from './toast.service'
import { UtilsService } from './utils.service'

@Injectable({ providedIn: 'root' })
export class EventService {
    FILTERS = {
        'Accès PMR': 'accessibility',
        'En Famille': 'family',
        Gratuit: 'free',
        'Chien accepté': 'dogs',
        Intérieur: 'indoor'
    }

    count: number
    currentEventPreview = false

    regIcon
    center: google.maps.LatLngLiteral
    zoom: number
    events: MargaretEvent[] = []
    mapEvents: MargaretEvent[] = [] // events displayed on the map
    mapMarkers: any[] = []

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        private httpClient: HttpClient,
        private utilsSrv: UtilsService,
        private toastService: ToastService,
        private authSrv: AuthService
    ) {}

    TIMEOUT_ERROR_MESSAGE = "'Une erreur s'est produite, veuillez ressayer plus tard.'"
    ABUSIVE_ERROR_MESSAGE = "'Votre annonce ne respecte pas les conditions d'utilisation.'"

    buildCreateEventInput(input: BuildCreateEventInput): CreateEventInput {
        const newObject: CreateEventInput = {
            organizer: input.currentOrganization.organizationUid,
            name: input.name,
            description: input.description,
            start_at: input.begin,
            finish_at: input.end,
            categoryId: input.categoryId.toString(),
            filters: input.filters.map(f => this.FILTERS[f]),
            address_gps_lat_cache: input.gps_lat,
            address_gps_lon_cache: input.gps_lng,
            created_by: input.currentUser.id,
            phone: input.currentUser.phone,
            // addressName: input.addressName,
            addressStreet: input.street || '',
            addressGpsLat: input.gps_lat,
            addressGpsLon: input.gps_lng,
            addressZipcode: input.zipcode,
            addressCity: input.city,
            statusId: input.status
        }
        Number(input.addressId) == -1 ? null : (newObject.addressId = input.addressId)

        return newObject
    }

    getCount() {
        return this.httpClient.get(`${environment.apiV3Url}/events/count`).pipe(
            map((count: any) => {
                this.count = count.totalEvents
                return count
            })
        )
    }

    // Vitrine
    getEventByUniqueId(uniqueId: string): Observable<MGEvent> {
        return this.httpClient.get(`${environment.apiV3Url}/events/${uniqueId}`).pipe(
            map((event: any) => {
                return event
            })
        )
    }

    // Dashboard
    getEventById(organizatioinId: number, eventId: number): Observable<MGEvent> {
        return this.httpClient
            .get(`${environment.apiV3Url}/organizations/${organizatioinId}/events/${eventId}`)
            .pipe(
                map((event: any) => {
                    return event
                })
            )
    }

    getLastEvents() {
        return this.httpClient.get<GetEventsData>(`${environment.apiV3Url}/events?latest`).pipe(
            map(events => {
                events = this.utilsSrv.imageUrl(events, 'events')

                this.events = events.primary.events

                this.mapEvents = this.events
                if (this.mapEvents.length > 0) {
                    this.mapMarkers = []
                    this.mapEvents.map((event: MargaretEvent) => {
                        event.address.position = {
                            lat: parseFloat(event.address.gps_lat),
                            lng: parseFloat(event.address.gps_lon)
                        }
                        this.mapMarkers.push(event)
                    })
                    if (isPlatformBrowser(this.platformId)) {
                        const newBoundary = new google.maps.LatLngBounds()
                        for (let index = 0; index < this.mapMarkers.length; index++) {
                            const position = this.mapMarkers[index].address.position
                            newBoundary.extend(position)
                        }
                    }
                } else {
                    // this.center = {
                    //     lat: 46.227638,
                    //     lng: 2.213749
                    // }
                }

                return events
            })
        )
    }

    getEvents(searchQueryString = '') {
        this.zoom = 5.5

        return this.httpClient
            .get<GetEventsData>(`${environment.apiV3Url}/events/${searchQueryString}`)
            .pipe(
                map((events: any) => {
                    events = this.utilsSrv.imageUrl(events, 'events')

                    this.events = [...this.events, ...events.primary.events]
                    events.primary.events.map((event: MargaretEvent) => {
                        event.address.position = {
                            lat: parseFloat(event.address.gps_lat),
                            lng: parseFloat(event.address.gps_lon)
                        }
                        event.icon = this.regIcon
                        this.mapMarkers.push(event)
                    })

                    const newBoundary = new google.maps.LatLngBounds()
                    for (let index = 0; index < this.mapMarkers.length; index++) {
                        const position = this.mapMarkers[index].address.position
                        newBoundary.extend(position)
                    }
                    const lat = newBoundary.getCenter().lat()
                    const lng = newBoundary.getCenter().lng()
                    this.center = { lat, lng }

                    return events
                })
            )
    }

    rate(uniqueId: string, rate: number, comment: string) {
        return this.httpClient
            .post(`${environment.apiV3Url}/events/${uniqueId}/ratings`, {
                rate,
                comment
            })
            .pipe(
                map((res: any) => {
                    return res
                })
            )
    }

    sendMessage(uniqueId: string, message: string): Observable<MGEvent> {
        return this.httpClient
            .post(`${environment.apiV3Url}/events/${uniqueId}/messages`, {
                message
            })
            .pipe(
                map((res: any) => {
                    return res
                })
            )
    }

    // From Annonceurs

    handleCreateOrUpdateError(response: any) {
        if (response instanceof TimeoutError) {
            this.toastService.createError(this.TIMEOUT_ERROR_MESSAGE, 3000)
            return
        }

        if (response.error.statusCode === 400 && response.error.message === 'Abusive content') {
            this.toastService.createError(this.ABUSIVE_ERROR_MESSAGE, 3000)
            return
        }

        this.toastService.createError(
            "Une erreur s'est produite. Veuillez ressayer plus tard.",
            3000
        )
    }

    searchEvents(searchQueryString = '') {
        return this.httpClient
            .get(
                `${environment.apiV3Url}/organizations/${this.authSrv.currentOrganization.id}/events/search?q=${searchQueryString}&p=0`
            )
            .pipe(
                map((event: any) => {
                    return event
                })
            )
    }
}
