<!-- <app-header-lg></app-header-lg> -->
<ion-content>
    <ion-grid>
        <ion-row *ngIf="!sessionSrv.askingCode">
            <ion-col>
                <app-profile-form></app-profile-form>
                <ion-button (click)="onLogin()" expand="block" fill="clear">
                    Vous avez déjà un compte ?
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="sessionSrv.askingCode">
            <ion-col sizeXs="12" sizeLg="10" offsetXs="0" offsetLg="1">
                <ion-input
                    type="text"
                    placeholder="Veuillez saisir le code reçu par e-mail"
                    [(ngModel)]="totpCode"
                ></ion-input>
                <ion-button (click)="checkCode()" expand="block" shape="round">Valider</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
    <!-- <app-footer></app-footer> -->
</ion-content>
