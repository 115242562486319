import { NgIf } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { RouterLink } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth.service'

@Component({
    selector: 'app-header-menu-popover-lg',
    templateUrl: './header-menu-popover-lg.component.html',
    styleUrls: ['./header-menu-popover-lg.component.scss'],
    standalone: true,
    imports: [IonicModule, NgIf, RouterLink]
})
export class HeaderMenuPopoverLgComponent implements OnInit {
    constructor(public authSrv: AuthService) {}

    ngOnInit() {
        this.authSrv.getOrganizations().subscribe()
    }

    async logout() {
        this.authSrv.logout().subscribe(() => {})
    }
}
