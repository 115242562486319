import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { environment } from '../../../../shared/environments/environment'
import { MGAlert } from '../../../../shared/models/alert'
import { GetAlertsData } from '../../../../shared/models/alert/create-alerts-data.model'
import { GetAlertData } from '../../../../shared/models/alert/get-alert-data.model'
import { UpdateAlertInput } from '../../../../shared/models/alert/update-alert-input.model'
import { MargaretEvent } from '../../../../shared/models/events/margaret-event.model'
import { CommonsService } from './commons.service'
import { ToastService } from './toast.service'

// TODO: extends to alert model ?

@Injectable({ providedIn: 'root' })
export class AlertService {
    currentAlerts
    currentAlertEvents: MargaretEvent[]
    organizations
    eventsCount: number

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private toastService: ToastService,
        private commonsService: CommonsService
    ) {
        this.eventsCount = 0
    }

    getSubscriptions() {
        // this.eventsCount = 0
        return this.httpClient.get(`${environment.apiV3Url}/me/bookmarks/organizations`).pipe(
            map(data => {
                this.organizations = data
                // this.currentAlerts.map(alert => {
                //     this.eventsCount += alert.unseenEvents
                // })
                return data
            })
        )
    }

    getAlerts() {
        this.eventsCount = 0
        return this.httpClient.get<GetAlertsData[]>(`${environment.apiV3Url}/me/alerts`).pipe(
            map(data => {
                this.currentAlerts = data
                this.currentAlerts.map(alert => {
                    if (alert.category) {
                        this.commonsService.data.categories.map(category => {
                            if (category.id === alert.category) {
                                alert.category = category
                            }
                        })
                        this.eventsCount += alert.unseenEvents
                    }

                    if (alert.criterias) {
                        const fullCriterias = []
                        alert.criterias.map(criteria => {
                            this.commonsService.data.criterias.find(criterion => {
                                if (criterion.id === criteria) {
                                    fullCriterias.push(criterion)
                                }
                            })
                        })
                        alert.criterias = fullCriterias
                    }

                    this.commonsService.data.actualites.map(actualite => {
                        if (actualite.id === alert.actualites) {
                            alert.actualites = actualite
                        }
                    })

                    if (alert.themes) {
                        const fullThemes = []
                        alert.themes.map(theme => {
                            this.commonsService.data.themes.find(thm => {
                                if (thm.id === theme) {
                                    fullThemes.push(thm)
                                }
                            })
                        })
                        alert.themes = fullThemes
                    }
                })
                return data
            })
        )
    }

    getAlert(id: string) {
        return this.httpClient.get<GetAlertData>(`${environment.apiV3Url}/alerts/${id}`).pipe(
            catchError((err, caugth) => {
                if (err.status === 403) {
                    this.router.navigate(['/home'])
                    this.toastService.createError('Vous ne pouvez pas accéder à cette alerte', 3000)
                    return throwError(`403 AlertService#getAlert for ${id}.`)
                }
                return throwError(`${err.status} AlertService#getAlert for ${id}.`)
            }),
            map(data => {
                return data
            })
        )
    }

    createAlert(alert) {
        // MGAlert type removed
        return this.httpClient.post<MGAlert>(`${environment.apiV3Url}/me/alerts`, alert).pipe(
            map(data => {
                // this.currentAlerts.push({ alert: data })
                return data
            })
        )
    }

    updateAlert(id: string, input: UpdateAlertInput) {
        return this.httpClient.put(`${environment.apiV3Url}/alerts/${id}`, input).pipe(
            map(data => {
                return data
            })
        )
    }

    deleteAlert(alert: MGAlert) {
        return this.httpClient.delete(`${environment.apiV3Url}/me/alerts/${alert.uniqueId}`).pipe(
            map(data => {
                return data
            })
        )
    }

    getEvents(alertUniqueId: string) {
        return this.httpClient
            .get<GetAlertsData[]>(`${environment.apiV3Url}/me/alerts/${alertUniqueId}`)
            .pipe(
                map(data => {
                    return data
                })
            )
    }
}
