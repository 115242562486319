import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { IonicModule } from '@ionic/angular';

@Component({
    selector: 'app-password-check',
    templateUrl: './password-check.component.html',
    styleUrls: ['./password-check.component.css'],
    standalone: true,
    imports: [IonicModule]
})
export class PasswordCheckComponent implements OnInit {
    @Input('password') password: string
    @Output() passwordOk = new EventEmitter<boolean>()

    lengthOk: boolean
    oneUpperCase: boolean
    oneLowerCase: boolean
    oneDigit: boolean
    oneSpecialChar: boolean

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        this.check(changes.password.currentValue)
    }

    check(password: string) {
        const lengthOk = new RegExp('(?=.{8,})')
        const oneUpperCase = new RegExp('(?=.*[A-Z])')
        const oneLowerCase = new RegExp('(?=.*[a-z])')
        const oneDigit = new RegExp('(?=.*[0-9])')
        const oneSpecialChar = new RegExp('(?=.*[^A-Za-z0-9])')
        this.lengthOk = lengthOk.test(password)
        this.oneUpperCase = oneUpperCase.test(password)
        this.oneLowerCase = oneLowerCase.test(password)
        this.oneDigit = oneDigit.test(password)
        this.oneSpecialChar = oneSpecialChar.test(password)

        const passwordOk =
            this.lengthOk &&
            this.oneUpperCase &&
            this.oneLowerCase &&
            this.oneDigit &&
            this.oneSpecialChar
                ? true
                : false
        this.passwordOk.emit(passwordOk)
    }
}
