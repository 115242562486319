<ion-list lines="none">
    <div *ngIf="authSrv.isAuth">
        <ion-item detail="false" routerLink="/home">
            <ion-label><ion-text>Accueil</ion-text></ion-label>
        </ion-item>
        <ion-item detail="false" routerLink="/profile/edit">
            <ion-label><ion-text>Mon profil</ion-text></ion-label>
        </ion-item>
        <ion-item
            button
            detail="false"
            [routerLink]="
                authSrv.currentUserOrganizations?.length > 0 ? '/dashboard' : '/organizations/new'
            "
        >
            <ion-label>
                <ion-text color="orange">
                    <ng-container *ngIf="authSrv.currentUserOrganizations?.length > 0">
                        Mes organisations
                    </ng-container>
                    <ng-container *ngIf="authSrv.currentUserOrganizations?.length === 0">
                        Créez votre organisation
                    </ng-container>
                </ion-text>
            </ion-label>
        </ion-item>
        <ion-item button detail="false" (click)="logout()">
            <ion-label><ion-text>Déconnexion</ion-text></ion-label>
        </ion-item>
    </div>
</ion-list>
