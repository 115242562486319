import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import {
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators
} from '@angular/forms'
import { Router } from '@angular/router'
import { AlertController, IonicModule, ModalController, ToastController } from '@ionic/angular'
import { format, formatDistance, formatISO, parseISO } from 'date-fns'
// import { GooglePlaceDirective } from 'ngx-google-places-autocomplete'
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete'
import { NgFor, NgIf } from '@angular/common'
import frLocale from 'date-fns/locale/fr'
import { NgxMaskDirective } from 'ngx-mask'
import { AuthService } from 'src/app/services/auth.service'
import { GooglePlaceService } from 'src/app/services/google-place.service'
import { ImagePreviewService } from 'src/app/services/image-preview.service'
import { SessionService } from 'src/app/services/session.service'
import { ToastService } from 'src/app/services/toast.service'
import { UserService } from 'src/app/services/user.service'
import { UtilsService } from 'src/app/services/utils.service'
import { PasswordPolicies, PasswordValidator } from 'src/app/validators/password.validator'
import { CurrentUser } from '../../../../../../shared/models/auth/current-user.model'
import { PlaceInfos } from '../../../../../../shared/models/google-map/place-infos.model'
import { LegalUseComponent } from '../legal-use/legal-use.component'
import { PasswordCheckComponent } from '../password-check/password-check.component'

@Component({
    selector: 'app-profile-form',
    templateUrl: 'profile-form.component.html',
    styleUrls: ['profile-form.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        NgFor,
        // NgxMaskModule,
        NgxMaskDirective,
        NgxGpAutocompleteModule,
        PasswordCheckComponent
    ]
})
export class ProfileFormComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('places') places /*: GooglePlaceDirective*/
    private readonly DEFAULT_AVATAR = '/assets/icon/margaret.svg'

    @Input() currentUser: CurrentUser
    mode: 'new' | 'edit' = 'new'
    avatar = this.DEFAULT_AVATAR
    loading = false
    mfa = false

    profileForm: FormGroup
    segmentButtonValue: string
    policies: PasswordPolicies = []

    // today: string
    placeFormatted: PlaceInfos
    options = {
        bounds: undefined,
        componentRestrictions: { country: 'FR' },
        // types: ['establishment', 'geocode'],
        types: [],
        fields: ['address_component', 'formatted_address', 'name', 'geometry'],
        strictBounds: false,
        origin: undefined
    }

    oldPasswordType: 'text' | 'password' = 'password'
    passwordConfirmType: 'text' | 'password' = 'password'
    passwordType: 'text' | 'password' = 'password'
    passwordOk: boolean

    constructor(
        public authService: AuthService,
        private toastService: ToastService,
        private router: Router,
        public userService: UserService,
        public imagePreviewService: ImagePreviewService,
        private toastCtrl: ToastController,
        private passwordValidator: PasswordValidator,
        private modalController: ModalController,
        private googlePlaceService: GooglePlaceService,
        private alertController: AlertController,
        public sessionSrv: SessionService,
        private utilsSrv: UtilsService
    ) {}

    ngOnInit() {
        this.segmentButtonValue = 'profile'

        this.profileForm = new FormGroup({
            gender: new FormControl(this.userService.genders[2].id, []),
            dob: new FormControl('', []),
            firstname: new FormControl('', [Validators.required]),
            lastname: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.email]),
            // phone: new FormControl('', [Validators.pattern('[0-9]{10,10}')]),
            phone: new FormControl('', []),
            location: new FormControl('', []),
            oldPassword: new FormControl(
                '',
                this.segmentButtonValue === 'passwordSegment' ? [Validators.required] : []
            ),
            password: new FormControl('', [Validators.required]),
            passwordConfirmation: new FormControl('', [Validators.required]),
            acceptedCGU: new FormControl(false, [])
        })

        this.imagePreviewService.files = []

        if (this.currentUser) {
            this.mode = 'edit'
            this.profileForm.controls['oldPassword'].clearValidators()
            this.profileForm.controls['password'].clearValidators()
            this.profileForm.controls['passwordConfirmation'].clearValidators()
            this.profileForm.controls['acceptedCGU'].clearValidators()

            const dob = this.currentUser.dob
                ? format(parseISO(this.currentUser.dob), 'ddMMyyyy')
                : ''

            const userGender = this.userService.genders.find(item => {
                return item.label === this.currentUser.gender
            })

            this.profileForm.setValue({
                gender: userGender.id, //this.currentUser.gender?.id || this.userService.genders[2].id,
                dob,
                firstname: this.currentUser.firstname || '',
                lastname: this.currentUser.lastname || '',
                email: this.currentUser.email,
                phone: this.currentUser.phone || '',
                location: this.currentUser.location || '',
                oldPassword: '',
                password: '',
                passwordConfirmation: '',
                acceptedCGU: true
            })

            this.avatar = this.currentUser.avatar
            this.mfa = this.currentUser.mfa
        }
    }

    ngAfterViewInit(): void {
        // this.places.reset()
    }

    ngOnDestroy(): void {
        this.imagePreviewService.files = []
    }

    since() {
        return formatDistance(new Date(), new Date(this.authService.currentUser.createdAt), {
            locale: frLocale
        })
    }

    onChangeDoubleAuth(event) {
        const mfa = event.detail.checked
        const oldMfa = this.mfa
        this.mfa = mfa

        // TODO can't know Double Auth status from API
        const sub = this.authService.toggleDoubleAuth().subscribe(
            res => {
                const currentUser = { ...this.currentUser }
                currentUser.mfa = this.mfa
                this.authService.changeCurrentUser(currentUser)
            },
            error => {
                this.mfa = oldMfa
                this.toastService.createError(
                    "Une erreur s'est produite lors du changement de double authentification",
                    2000
                )
            }
        )

        // sub.unsubscribe();
    }

    // onUpload(event) {
    //     this.imagePreviewService.files = []
    //     this.imagePreviewService.addImage(event.currentTarget)
    // }

    async update() {
        const idx = this.userService.genders.findIndex(gender => {
            return gender.id == this.profileForm.value.gender
        })
        const gender = this.userService.genders[idx]

        if (
            this.profileForm.get('firstname').invalid ||
            this.profileForm.get('lastname').invalid ||
            this.profileForm.get('email').invalid ||
            this.profileForm.get('phone').invalid
        ) {
            const toast = await this.toastCtrl.create({
                message: 'Il manque des informations...',
                duration: 3000,
                color: 'danger'
            })
            await toast.present()
            return
        }

        const formData = new FormData()
        // formData.append('uniqueid', this.currentUser.uniqueId)

        this.policies = this.passwordValidator.checkPasswordPolicy(
            this.profileForm.value.password
        ).policies
        const passwordValid = this.passwordValidator.checkPasswordPolicy(
            this.profileForm.value.passwordConfirmation
        ).valid

        const equalsPasswords =
            this.profileForm.value.password == this.profileForm.value.passwordConfirmation
                ? true
                : false

        if (passwordValid && equalsPasswords && this.segmentButtonValue === 'passwordSegment') {
            formData.append('uniqueId', this.authService.currentUser.uniqueId)
            formData.append('oldPassword', this.profileForm.value.oldPassword)
            formData.append('password', this.profileForm.value.password)

            this.userService.updatePassword(formData).subscribe(
                () => {
                    this.toastService.create(`Votre mot de passe a bien été modifié.`, 1500)
                    this.profileForm.value.password = ''
                    this.profileForm.value.passwordConfirmation = ''
                    this.router.navigate(['/profile'])
                },
                error => {
                    this.toastService.createError(
                        `Une erreur s'est produite lors de l'édition de votre mot de passe`,
                        1500
                    )
                }
            )
        } else if (this.segmentButtonValue === 'profile') {
            this.loading = true
            formData.append('uniqueId', this.authService.currentUser.uniqueId)
            formData.append('gender', gender.label)
            formData.append('firstname', this.profileForm.value.firstname)
            formData.append('lastname', this.profileForm.value.lastname)
            formData.append('location', this.profileForm.value.location)

            const dob =
                this.profileForm.value.dob === '' ||
                this.profileForm.value.dob === null ||
                this.profileForm.value.dob === undefined
                    ? ''
                    : formatISO(this.utilsSrv.maskedString2ISODate(this.profileForm.value.dob))
            if (dob) {
                formData.append(
                    'dob',
                    formatISO(this.utilsSrv.maskedString2ISODate(this.profileForm.value.dob))
                )
            }

            formData.append('email', this.profileForm.value.email)
            formData.append('phone', this.profileForm.value.phone)

            const avatarImg = await this.imagePreviewService.getImageFileFormat()

            if (this.imagePreviewService.imageTooHeavy) {
                this.loading = false
                return
            }

            // const files = this.imagePreviewService.getFiles()
            // const avatarImg = files[0]
            if (avatarImg) {
                formData.append('avatar', avatarImg, 'avatar')
            }

            this.userService.updateProfile(formData).subscribe(
                userData => {
                    this.loading = false
                    this.authService.changeCurrentUser(userData)
                    this.toastService.create(`Votre profil a été mis à jour.`, 3000)
                    this.router.navigate(['/profile'])
                },
                error => {
                    this.loading = false
                    const message =
                        error.status === 409
                            ? 'Cette adresse e-mail est déjà utilisée.'
                            : `Une erreur s'est produite lors de la mise à jour de votre profil.`
                    this.toastService.createError(message, 3000)
                }
            )
        } else {
            const toast = await this.toastCtrl.create({
                message: 'Veuilez vérifier vos informations...',
                duration: 3000,
                color: 'danger'
            })
            await toast.present()
        }
    }

    async register() {
        const idx = this.userService.genders.findIndex(gender => {
            return gender.id == this.profileForm.value.gender
        })
        const gender = this.userService.genders[idx]

        this.policies = this.passwordValidator.checkPasswordPolicy(
            this.profileForm.value.password
        ).policies
        // const passwordValid = this.passwordValidator.checkPasswordPolicy(
        //     this.profileForm.value.passwordConfirmation
        // ).valid

        const equalsPasswords =
            this.profileForm.value.password == this.profileForm.value.passwordConfirmation
                ? true
                : false

        if (!equalsPasswords) {
            const toast = await this.toastCtrl.create({
                message: 'Les deux mots de passe ne sont pas identiques.',
                duration: 3000,
                color: 'danger'
            })
            await toast.present()
            return
        }

        if (this.passwordOk && equalsPasswords && this.profileForm.value.acceptedCGU) {
            this.loading = true

            const dob =
                this.profileForm.value.dob === '' ||
                this.profileForm.value.dob === null ||
                this.profileForm.value.dob === undefined
                    ? ''
                    : formatISO(this.utilsSrv.maskedString2ISODate(this.profileForm.value.dob))

            this.authService
                .createUser({
                    gender: gender.label,
                    firstname: this.profileForm.value.firstname,
                    lastname: this.profileForm.value.lastname,
                    dob,
                    email: this.profileForm.value.email,
                    password: this.profileForm.value.password,
                    phone: this.profileForm.value.phone,
                    location: this.profileForm.value.location,
                    acceptedCGU: this.profileForm.value.acceptedCGU
                })
                .subscribe(
                    res => {
                        this.loading = true
                        this.sessionSrv.tempUserBeforeActivation = res
                        this.sessionSrv.tempUserBeforeActivation.mail = this.profileForm.value.email
                        this.sessionSrv.tempPasswordBeforeActivation =
                            this.profileForm.value.password
                        this.sessionSrv.askingCode = true
                    },
                    async error => {
                        let message = ''
                        switch (error.error.statusCode) {
                            case 409:
                                message = 'Cette adresse e-mail est déjà utilisée.'
                                break

                            default:
                                message = `Votre demande d'enregistrement n'a pas pu aboutir...`
                                break
                        }
                        this.loading = false
                        const toast = await this.toastCtrl.create({
                            message,
                            duration: 3000,
                            color: 'danger'
                        })
                        await toast.present()
                    }
                )
        } else {
            const toast = await this.toastCtrl.create({
                message: 'Il manque des informations...',
                duration: 3000,
                color: 'danger'
            })
            await toast.present()
        }
    }

    async openCGU() {
        const modal = await this.modalController.create({
            component: LegalUseComponent,
            cssClass: 'legal-use-modal'
        })

        await modal.present()
    }

    async handleAddressChange(event: any) {
        this.placeFormatted = this.googlePlaceService.getInfos(event)

        // if (!this.placeFormatted.zipcode) {
        //     const toast = await this.toastController.create({
        //         message: `Un code postal est obligatoire dans l'adresse.`,
        //         duration: 5000,
        //         color: 'danger'
        //     })
        //     await toast.present()
        //     return
        // }
        this.mode == 'edit' ? (this.currentUser.location = this.placeFormatted.city) : null
    }

    onShowPassword(input: string) {
        switch (input) {
            case 'old':
                this.oldPasswordType = this.oldPasswordType == 'text' ? 'password' : 'text'

                break

            case 'pass':
                this.passwordType = this.passwordType == 'text' ? 'password' : 'text'

                break

            case 'conf':
                this.passwordConfirmType = this.passwordConfirmType == 'text' ? 'password' : 'text'
                break

            default:
                break
        }
    }

    async onGetPhoto() {
        this.imagePreviewService.onGetPhoto().then(() => {
            this.avatar = undefined
            this.imagePreviewService.files = []
        })
    }

    getAvatar() {
        if (this.avatar) {
            return this.avatar
        } else if (this.imagePreviewService.currentBase64Logo) {
            return this.imagePreviewService.currentBase64Logo
        }
        return '/assets/icon/margaret.svg'
    }

    async logout() {
        this.authService.logout().subscribe()
    }

    async onDestroy() {
        const alert = await this.alertController.create({
            header: 'Attention !',
            message: `Votre compte Margaret, ainsi que tout ce qu'il contient est sur le point d'être détruit.`,
            buttons: [
                {
                    text: 'Annuler',
                    role: 'cancel'
                },
                {
                    text: 'Supprimer',
                    role: 'destructive',
                    handler: () => {
                        this.authService.deleteUser().subscribe(
                            res => {},
                            err => {
                                if (err.status == 409) {
                                    this.toastService.createError(
                                        `Votre compte n'a pas pu être supprimé car vous gérez au moins une organisation.`,
                                        5000
                                    )
                                }
                            }
                        )
                    }
                }
            ]
        })

        await alert.present()
    }

    isPasswordOk(event) {
        this.passwordOk = event
    }
}
