import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import {
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators
} from '@angular/forms'
import { Router } from '@angular/router'
import {
    SignInWithApple,
    SignInWithAppleOptions,
    SignInWithAppleResponse
} from '@capacitor-community/apple-sign-in'
// import { FacebookLogin } from '@capacitor-community/facebook-login'
import { NgIf } from '@angular/common'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { IonicModule, ModalController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth.service'
import { CategoryService } from 'src/app/services/category.service'
import { EventService } from 'src/app/services/event.service'
import { OrganizationService } from 'src/app/services/organization.service'
import { SessionService } from 'src/app/services/session.service'
import { ToastService } from 'src/app/services/toast.service'
import { UserService } from 'src/app/services/user.service'
import { environment } from '../../../../../../../shared/environments/environment'
import { AcceptCguModalComponent } from '../../accept-cgu-modal/accept-cgu-modal.component'

@Component({
    selector: 'app-omni',
    templateUrl: './omni.component.html',
    styleUrls: ['./omni.component.scss'],
    standalone: true,
    imports: [IonicModule, NgIf, FormsModule, ReactiveFormsModule]
})
export class OmniComponent implements OnInit, AfterViewInit, OnDestroy {
    // doubleAuth = false
    // mfa = ''
    // marker = ''
    loginForm: FormGroup
    passwordConfirmType: 'text' | 'password' = 'password'
    passwordType: 'text' | 'password' = 'password'

    constructor(
        public userSrv: UserService,
        private modalController: ModalController,
        public authService: AuthService,
        public sessionSrv: SessionService,
        private toastService: ToastService,
        private router: Router,
        private eventsSrv: EventService,
        private categoriesSrv: CategoryService,
        private organizationSrv: OrganizationService
    ) {}

    ngOnInit() {
        this.loginForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required])
        })
    }

    ngAfterViewInit(): void {
        GoogleAuth.initialize({
            clientId: '18216916625-t9pbo0qpi6lplcciseomejb8pic1ngon.apps.googleusercontent.com',
            scopes: ['profile', 'email'],
            grantOfflineAccess: true
        })
    }

    ngOnDestroy(): void {
        // this.sessionSrv.onboarding = false
    }

    onAppleSignin() {
        const options: SignInWithAppleOptions = {
            clientId: environment.appleClientID || '',
            redirectURI: environment.appleRedirectURI || '',
            scopes: 'email name',
            state: '19117219',
            nonce: 'nonce'
        }
        SignInWithApple.authorize(options)
            .then(async (result: SignInWithAppleResponse) => {
                const body: any = result.response
                const fcmToken = await this.authService.getFcmToken()
                body.fcmToken = fcmToken
                this.authService.socialLogin('ac', body).subscribe(async res => {
                    if (!this.authService.currentUser?.acceptedCGU) {
                        // CHECK refactoring ?
                        const modal = await this.modalController.create({
                            component: AcceptCguModalComponent,
                            cssClass: 'legal-use-modal'
                        })
                        await modal.present()
                        const data = await modal.onDidDismiss()
                        if (!data.data?.cgu) {
                            this.authService.logUserOut()
                        } else {
                            this.loginOk()
                        }
                    } else {
                        this.modalController.dismiss()
                    }
                })
            })
            .catch(error => {})
    }

    onGoogleSignin() {
        GoogleAuth.signIn()
            .then(
                async (res: any) => {
                    const body: any = {
                        identityToken: res.authentication.idToken
                    }
                    const fcmToken = await this.authService.getFcmToken()
                    body.fcmToken = fcmToken
                    this.authService.socialLogin('go', body).subscribe(async () => {
                        // if (!this.authService.currentUser.acceptedCGU) {
                        // CHECK refactoring ?
                        const modal = await this.modalController.create({
                            component: AcceptCguModalComponent,
                            cssClass: 'legal-use-modal'
                        })
                        await modal.present()
                        const data = await modal.onDidDismiss()
                        if (!data.data?.cgu) {
                            this.authService.logUserOut()
                        } else {
                            this.loginOk()
                        }
                        // } else {
                        // this.modalController.dismiss()
                        // }
                    })
                },
                err => {}
            )
            .catch(error => {})
    }

    // async onFacebookSignin() {
    //     await FacebookLogin.initialize({ appId: '356656386476819' })
    //     // const FACEBOOK_PERMISSIONS = ['email', 'public_profile', 'user_birthday']
    //     const FACEBOOK_PERMISSIONS = []
    //     const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
    //     if (!result) return
    //     if (result.accessToken) {
    //         const resultProfile: any = await FacebookLogin.getProfile({
    //             fields: ['id', 'email', 'first_name', 'last_name', 'picture']
    //         })
    //         const fcmToken = await this.authService.getFcmToken()
    //         const res = {
    //             authToken: result.accessToken.token,
    //             email: resultProfile.email,
    //             firstName: resultProfile.first_name,
    //             id: resultProfile.id,
    //             lastName: resultProfile.last_name,
    //             photoUrl: resultProfile.picture.data.url,
    //             fcmToken
    //         }
    //         this.authService.socialLogin('fb', res).subscribe(async () => {
    //             if (!this.authService.cguAccepted) {
    //                 const modal = await this.modalController.create({
    //                     component: AcceptCguModalComponent,
    //                     cssClass: 'legal-use-modal'
    //                 })
    //                 await modal.present()
    //                 const data = await modal.onDidDismiss()
    //                 if (!data.data?.cgu) {
    //                     this.authService.logUserOut()
    //                 } else {
    //                     this.loginOk()
    //                 }
    //             } else {
    //                 this.modalController.dismiss()
    //             }
    //         })
    //     }
    // }

    // private loginOk() {
    //     this.modalController.dismiss()
    //     this.toastService.create('Vous êtes maintenant connecté.', 3000)
    //     if (this.router.url.includes('home')) {
    //         this.eventsSrv.getLastEvents().subscribe()
    //     }
    //     this.router.navigate(['/home'])
    // }

    private loginOk() {
        // FIXME: (duplicate with home.component) => move to auth service ?
        this.modalController.dismiss()
        // this.categoriesSrv.getCategories().subscribe()
        // this.authService.getOrganizations().subscribe((organizations: any) => {
        //     this.authService.currentUserOrganizations = organizations

        //     if (this.authService.currentUserOrganizations.length > 0) {
        //         this.authService.currentOrganization = this.authService.currentUserOrganizations[0]
        //         this.authService
        //             .changeOrganization(this.authService.currentOrganization)
        //             .subscribe(() => {
        //                 this.sessionSrv.onboarding
        //                     ? this.router.navigate(['/events/new'])
        //                     : this.router.navigate(['/home'])
        //             })
        //     } else {
        //         this.router.navigate(['/organizations/new'])
        //     }
        // })
    }

    onShowPassword(input: string) {
        switch (input) {
            case 'pass':
                this.passwordType = this.passwordType == 'text' ? 'password' : 'text'

                break

            case 'conf':
                this.passwordConfirmType = this.passwordConfirmType == 'text' ? 'password' : 'text'
                break

            default:
                break
        }
    }

    onPassword() {
        this.sessionSrv.authPage = 'password-reset'
    }

    async onSubmit() {
        this.authService.newLogin(this.loginForm.value.email, this.loginForm.value.password)
        // const fcmToken = await this.authService.getFcmToken()
        // this.authService.loginFormCredentials = {
        //     username: this.loginForm.value.email,
        //     password: this.loginForm.value.password,
        //     fcmToken
        // }
        // this.authService
        //     .login({
        //         username: this.loginForm.value.email,
        //         password: this.loginForm.value.password,
        //         fcmToken
        //     })
        //     .subscribe({
        //         next: (token: string) => {
        //             this.authService.loginUser(token)
        //             this.loginOk()
        //         },
        //         error: error => {
        //             if (error.reason === 'mfa') {
        //                 this.toastService.create('Veuillez saisir le code reçu par email', 3000)
        //                 this.marker = error.marker
        //                 this.doubleAuth = true
        //             }
        //         }
        //     })
    }

    onSignup() {
        this.sessionSrv.authPage = 'signup'
    }

    checkMfa() {
        this.authService
            .checkDoubleAuth({ mfaCode: this.authService.mfa, marker: this.authService.marker })
            .subscribe(
                () => {
                    this.modalController.dismiss()
                },
                error => {
                    switch (error.status) {
                        case 401:
                            this.toastService.createError('Le code est invalide', 3000)
                            break

                        case 409:
                            this.toastService.createError('Le code est expiré', 3000)
                            break

                        default:
                            this.toastService.createError('Une erreur est survenue', 3000)
                            break
                    }
                }
            )
    }
}
