import { Component } from '@angular/core'
import { IonicModule, ModalController } from '@ionic/angular'

@Component({
    selector: 'app-legal-use',
    templateUrl: 'legal-use.component.html',
    styleUrls: ['legal-use.component.scss'],
    standalone: true,
    imports: [IonicModule]
})
export class LegalUseComponent {
    constructor(private modalController: ModalController) {}

    onClose() {
        this.modalController.dismiss()
    }

    onCGU() {
        window.open('assets/pdf/CGU.pdf', '_blank')
    }

    onCGS() {
        window.open('assets/pdf/CGS.pdf', '_blank')
    }
}
