<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col col="12">
                <h3 class="ion-text-center">
                    <ion-text color="primary">Récupérez votre accès à Margaret</ion-text>
                </h3>
                <form [formGroup]="loginForm">
                    <ion-input
                        required
                        formControlName="email"
                        placeholder="Votre adresse e-mail*"
                    ></ion-input>
                    <div
                        *ngIf="
                            loginForm.get('email').invalid &&
                            (loginForm.get('email').dirty || loginForm.get('email').touched)
                        "
                    >
                        <ion-text color="danger">
                            Une adresse e-mail valide est obligatoire.
                        </ion-text>
                    </div>
                    <ion-button expand="block" color="orange" (click)="resetPassword()">
                        Récupérer mon mot de passe
                    </ion-button>
                </form>
                <ion-button (click)="onLogin()" expand="block" fill="clear">
                    Retour vers la connexion
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
