import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { IonicModule, ModalController, PopoverController } from '@ionic/angular'
import { AlertService } from 'src/app/services/alert.service'
import { AuthService } from 'src/app/services/auth.service'
import { FavoriteService } from 'src/app/services/favorites.service'
import { SearchService } from 'src/app/services/search.service'
import { SessionService } from 'src/app/services/session.service'
import { AuthComponent } from '../../pages/auth/auth.component'
import { HeaderMenuPopoverLgComponent } from '../header-menu-popover-lg/header-menu-popover-lg.component'
import { SearchBarComponent } from '../search-bar/search-bar.component'

@Component({
    selector: 'app-header-lg',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [IonicModule, CommonModule, SearchBarComponent]
})
export class HeaderComponent implements OnInit {
    // searchBarKingSize: boolean

    constructor(
        public authService: AuthService,
        public router: Router,
        private popoverController: PopoverController,
        private favoriteService: FavoriteService,
        public searchService: SearchService,
        public alertSrv: AlertService,
        public sessionSrv: SessionService,
        private modalController: ModalController
    ) {}

    ngOnInit() {}

    async onAuth(event) {
        this.authService.isAuth ? this.presentPopover(event) : await this.presentModal()
    }

    async presentPopover(ev: any) {
        const popover = await this.popoverController.create({
            component: HeaderMenuPopoverLgComponent,
            dismissOnSelect: true,
            event: ev,
            translucent: true,
            cssClass: !this.authService.isAuth ? 'popover-menu-small' : ''
        })
        popover.shadowRoot.lastChild.lastChild['style'].cssText = 'border-radius: 12px !important;'
        await popover.present()
    }

    private async presentModal() {
        this.sessionSrv.authPage = 'omni'
        const modal = await this.modalController.create({
            component: AuthComponent,
            cssClass: 'full-popover',
            canDismiss: true
        })
        await modal.present()
    }

    onAlerts() {
        this.alertSrv.getAlerts().subscribe(res => {
            this.router.navigate(['/alerts'])
        })
    }

    onFavorites() {
        this.favoriteService.getFavorites().subscribe(() => {
            this.router.navigate(['/favorites'])
        })
    }

    backToHome() {
        this.searchService.reset()
        this.router.navigate(['/home'], { replaceUrl: true })
    }

    async onboarding() {
        if (!this.authService.isAuth) {
            this.sessionSrv.onboarding = true
            this.sessionSrv.authPage = 'omni'
            const modal = await this.modalController.create({
                component: AuthComponent,
                cssClass: 'full-popover',
                componentProps: {}
            })

            // modal.onDidDismiss().then(res => {
            //     this.sessionSrv.onboarding = true
            //     this.authService.getOrganizations().subscribe((organizations: any) => {
            //         organizations.length > 0
            //             ? this.getData()
            //             : this.router.navigate(['/organizations/new'])
            //     })
            // })

            await modal.present()
        } else {
            if (this.authService.currentOrganization == undefined) {
                this.authService.getOrganizations().subscribe((organizations: any) => {
                    organizations.length > 0
                        ? this.getData()
                        : this.router.navigate(['/organizations/new'])
                })
            } else {
                this.getData()
            }
        }
    }

    private getData() {
        this.sessionSrv.onboarding = false
        if (this.authService.currentUserOrganizations.length > 0) {
            if (this.authService.currentOrganization == undefined) {
                this.authService.currentOrganization = this.authService.currentUserOrganizations[0]
            }
            this.authService
                .changeOrganization(this.authService.currentOrganization)
                .subscribe(() => {
                    this.router.navigate(['/dashboard/event'])
                    // this.statsService
                    //     .getOrganizationStats(this.authService.currentOrganization.id)
                    //     .subscribe(stats => {
                    //         this.stats = stats
                    //     })
                })
        }
    }
}
