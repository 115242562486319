<ion-header>
    <ion-toolbar>
        <ion-title>Merci d'accepter les CGU</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-item lines="none" class="ion-item-clear">
                    <ion-checkbox
                        color="primary"
                        [(ngModel)]="cgu"
                        class="ion-text-wrap"
                        justify="space-between"
                    >
                        J'accepte les conditions générales de l'application Margaret
                    </ion-checkbox>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col class="ion-text-center">
                <ion-button fill="clear" (click)="openCGU()">(lire les conditions)</ion-button>
                <ion-button
                    (click)="onAccept()"
                    expand="block"
                    shape="round"
                    color="primary"
                    [disabled]="!cgu"
                >
                    Valider
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-footer>
    <ion-button (click)="onCancel()" expand="block" shape="round" color="light">Refuser</ion-button>
</ion-footer>
