import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    authPage: string
    askingCode: boolean
    tempUserBeforeActivation: any
    tempPasswordBeforeActivation: string
    changePasswordJwt: string
    showAuthModal: boolean
    onboarding: boolean
    responsive: boolean
    crossLogout: boolean
    showCalendar: boolean

    // From Annonceurs
    searchBarKingSize: boolean
    currentButton: string
    premiumFunctionality = 'Cette fonctionnalité est réservée aux organisations abonnées à un pack.'

    constructor() {
        this.authPage = 'omni'
        this.askingCode = false
        this.showAuthModal = false
        this.onboarding = false
        this.responsive = false
        this.crossLogout = false
        this.searchBarKingSize = false
        this.showCalendar = false
    }
}
