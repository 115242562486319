import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { PushNotifications } from '@capacitor/push-notifications'
import { ModalController } from '@ionic/angular'
import { formatDistance, fromUnixTime, parseISO } from 'date-fns'
import { fr } from 'date-fns/locale'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging'
import { Observable, forkJoin, of, throwError } from 'rxjs'
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { environment } from '../../../../shared/environments/environment'
import { CheckDoubleAuthInput } from '../../../../shared/models/auth/check-double-auth-input.model'
import { CreateUserInput } from '../../../../shared/models/auth/create-user-input.model'
import { CurrentUser } from '../../../../shared/models/auth/current-user.model'
import { LoggedDevice } from '../../../../shared/models/auth/logged-device.model'
import { LoginData } from '../../../../shared/models/auth/login-data.model'
import { LoginUserInput } from '../../../../shared/models/auth/login-user-input.model'
import { MGEvent } from '../../../../shared/models/events/margaret-event.model'
import { MGOrganization } from '../../../../shared/models/organization'
import { MGOrganizationMember } from '../../../../shared/models/organization-member'
import { User } from '../../../../shared/models/user/user.model'
import { AlertService } from './alert.service'
import { CategoryService } from './category.service'
import { FavoriteService } from './favorites.service'
import { JwtService } from './jwt.service'
import { OrganizationService } from './organization.service'
import { MGOrganizationMembership } from './premium.service'
import { SearchService } from './search.service'
import { SessionService } from './session.service'
import { ToastService } from './toast.service'
import { UserService } from './user.service'

const addListeners = async () => {
    await PushNotifications.addListener('registration', token => {})
    await PushNotifications.addListener('registrationError', err => {})
    await PushNotifications.addListener('pushNotificationReceived', () => {})
    await PushNotifications.addListener('pushNotificationActionPerformed', () => {})
}

const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions()
    if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions()
    }
    if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!')
    }
    await PushNotifications.register()
}

const getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications()
}

export interface MGOrganizationStats {
    published: number
    over: number
    cancelled: number
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    cguAccepted: string = null
    isAuth = false
    token: string
    currentUser: CurrentUser
    loginFormCredentials: LoginUserInput

    private tokenTimer: any

    // From Annonceurs
    currentOrganization: any
    currentOrganizationData // TODO move to orga srv
    currentOrganizationSubscriptionDistance // TODO move to orga srv
    currentOrganizationMembers: MGOrganizationMember[] // TODO move to orga srv
    currentOrganizationEvents: MGEvent[] // TODO move to orga srv
    currentOrganizationEventsCancelled: MGEvent[] // TODO move to orga srv
    currentOrganizationEventsOver: MGEvent[] // TODO move to orga srv
    currentOrganizationMembership: MGOrganizationMembership
    currentUserOrganizations: MGOrganization[] = []
    currentOrganizationEventsTotal: number
    currentOrganizationEventsNextPage: number
    currentOrganizationStats: MGOrganizationStats
    currentOrganisationPack: string
    draftsEvents = 0
    doubleAuth = false
    mfa = ''
    marker = ''

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private toastService: ToastService,
        private jwtService: JwtService,
        private favoriteService: FavoriteService,
        private userSrv: UserService,
        private alertSrv: AlertService,
        private searchSrv: SearchService,
        private categoryService: CategoryService,
        private modalController: ModalController,
        private organizationService: OrganizationService,
        private sessionSrv: SessionService
    ) {}

    getMargaretToken() {
        return this.token
    }

    isLogged() {
        return this.currentUser !== undefined
    }

    passwordReset(mail: string) {
        return this.httpClient.post(`${environment.apiV3Url}/users/forgot-password`, { mail }).pipe(
            map(
                (response: any) => {
                    return response
                },
                err => {
                    return err
                }
            )
        )
    }

    passwordNewConfirm(newPassword: string, token: string) {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + token
        })

        return this.httpClient
            .post(`${environment.apiV3Url}/users/reset-password`, { newPassword }, { headers })
            .pipe(
                map(
                    (response: any) => {
                        return response
                    },
                    err => {
                        return err
                    }
                )
            )
    }

    async getFcmToken() {
        const supported = await isSupported()
        if (!supported) {
            return ''
        }
        const firebaseConfig = {
            apiKey: 'AIzaSyBZiMLIuhJG4lvVyLQU4S2X-EUiTzLMaQQ',
            authDomain: 'margaret-c8c69.firebaseapp.com',
            projectId: 'margaret-c8c69',
            storageBucket: 'margaret-c8c69.appspot.com',
            messagingSenderId: '18216916625',
            appId: '1:18216916625:web:cc544ed2d64c73ebea5130',
            measurementId: 'G-14785FLSRN',
            vapidKey:
                'BH4rQIjNzy4EwwFR9mTMH428r77IB5FgWvl9yNHHz4jhwxJlP8attaWu_bjrw9lwspK7fswR4lmogFLnPlL57d0'
        }
        let fcmToken

        const app = initializeApp(firebaseConfig)
        const messaging = getMessaging(app)
        onMessage(messaging, payload => {
            if (payload.data.type == 'alert') this.alertSrv.getAlerts().subscribe()
            new Notification(payload.notification.title, {
                body: payload.notification.body,
                icon: payload.notification.image
            })
        })
        await getToken(messaging, { vapidKey: firebaseConfig.vapidKey }).then(
            token => {
                fcmToken = token
            },
            err => {
                fcmToken = ''
            }
        )

        return fcmToken
    }

    async newLogin(username: string, password: string) {
        const fcmToken = await this.getFcmToken()
        this.loginFormCredentials = {
            username,
            password,
            fcmToken
        }
        this.httpClient
            .post<LoginData>(`${environment.apiV3Url}/login`, this.loginFormCredentials)
            .pipe(
                map(data => {
                    if (data.marker) {
                        this.doubleAuth = true
                        this.marker = data.marker
                        return throwError({
                            reason: 'mfa',
                            marker: data.marker
                        })
                    }
                    if (data.access_token) {
                        this.doubleAuth = false
                        this.isAuth = true
                        this.token = data.access_token

                        const decodedToken = this.jwtService.decode(data.access_token)

                        // this.cguAccepted = decodedToken.accepted_terms

                        const exp = decodedToken.exp

                        this.userSrv.getUser().subscribe((res: CurrentUser) => {
                            this.getOrganizations().subscribe(organizations => {
                                organizations.length > 0
                                    ? this.getData()
                                    : this.router.navigate(['/organizations/new'])
                            })

                            this.currentUser = res

                            const expirationDate = fromUnixTime(exp)
                            const expiresIn = this.expiresIn(expirationDate)
                            this.setAuthTimer(expiresIn / 1000)

                            this.jwtService.saveAuthData(
                                data.access_token,
                                expirationDate,
                                this.currentUser
                            )
                            this.isAuth = true
                            this.modalController.dismiss()
                            this.toastService.create('Vous êtes maintenant connecté', 5000)
                        })
                    }
                }),
                catchError(err => {
                    if (err.error?.statusCode == 401) {
                        this.toastService.createError(
                            'Adresse e-mail ou mot de passe non valide',
                            2000
                        )
                    } else if (err.reason == 'mfa') {
                        return throwError(err)
                    } else {
                        this.toastService.createError('Une erreur est survenue', 2000)
                    }
                    return throwError(err)
                })
            )
            .subscribe()
    }

    private getData() {
        this.sessionSrv.onboarding = false
        if (this.currentUserOrganizations.length > 0) {
            if (this.currentOrganization == undefined) {
                this.currentOrganization = this.currentUserOrganizations[0]
            }
            this.changeOrganization(this.currentOrganization).subscribe(() => {
                this.router.navigate(['/dashboard/event'])
                // this.statsService
                //     .getOrganizationStats(this.authService.currentOrganization.id)
                //     .subscribe(stats => {
                //         this.stats = stats
                //     })
            })
        }
    }

    login(credentials: LoginUserInput) {
        return this.httpClient.post<LoginData>(`${environment.apiV3Url}/login`, credentials).pipe(
            map(data => {
                if (data.marker) {
                    return throwError({
                        reason: 'mfa',
                        marker: data.marker
                    })
                }
                if (data.access_token) {
                    return data.access_token
                }
            }),
            catchError(err => {
                if (err.error?.statusCode == 401) {
                    this.toastService.createError('Adresse e-mail ou mot de passe non valide', 2000)
                } else if (err.reason == 'mfa') {
                    return throwError(err)
                } else {
                    this.toastService.createError('Une erreur est survenue', 2000)
                }
                return throwError(err)
            })
        )
    }

    checkDoubleAuth(input: CheckDoubleAuthInput) {
        return this.httpClient.post<LoginData>(`${environment.apiV3Url}/login/mfa`, input).pipe(
            switchMap(data => {
                if (data.access_token) {
                    this.doubleAuth = false
                    this.mfa = ''
                    this.marker = ''
                    this.loginUser(data.access_token)
                    return of(this.currentUser)
                }
            }),
            mergeMap(user => {
                return forkJoin({
                    currentUser: of(user)
                })
            }),
            map(data => {
                return data.currentUser
            })
        )
    }

    activateUser(userUid: string, totpCode: string) {
        return this.httpClient
            .put(`${environment.apiV3Url}/me/activate`, { userUid, totpCode })
            .pipe(
                map(
                    (response: any) => {
                        return response
                    },
                    err => {
                        return err
                    }
                )
            )
    }

    loginUser(access_token: string) {
        this.isAuth = true
        this.token = access_token

        const decodedToken = this.jwtService.decode(access_token)

        this.cguAccepted = decodedToken.acceptedCGU

        const exp = decodedToken.exp

        this.userSrv.getUser().subscribe((res: CurrentUser) => {
            const idx = this.userSrv.genders.findIndex(gender => {
                return gender.label == res.gender
            })

            this.currentUser = res

            const expirationDate = fromUnixTime(exp)
            const expiresIn = this.expiresIn(expirationDate)
            this.setAuthTimer(expiresIn / 1000)

            this.jwtService.saveAuthData(access_token, expirationDate, this.currentUser)
            this.isAuth = true
        })
    }

    toggleDoubleAuth() {
        return this.httpClient.put(`${environment.apiV3Url}/me/mfa`, {}).pipe(
            map(data => {
                return data
            })
        )
    }

    loggedDevices() {
        return this.httpClient.get<LoggedDevice[]>(`${environment.apiV3Url}/logged_devices`).pipe(
            map(data => {
                return data
            })
        )
    }

    logout() {
        return this.httpClient.post(`${environment.apiV3Url}/logout`, null).pipe(
            tap(() => {
                this.logUserOut()
            })
        )
    }

    logUserOut() {
        this.searchSrv.reset()
        clearTimeout(this.tokenTimer)
        this.isAuth = false
        this.token = ''
        this.currentUser = null
        this.isAuth = false
        this.jwtService.clearAuthData()
        this.alertSrv.currentAlerts = []
        this.alertSrv.organizations = []
        this.favoriteService.favoriteEvents = []

        this.currentOrganization = undefined
        this.currentOrganizationData = undefined
        this.currentOrganizationSubscriptionDistance = undefined
        this.currentOrganizationMembers = undefined
        this.currentOrganizationEvents = undefined
        this.currentOrganizationEventsCancelled = undefined
        this.currentOrganizationEventsOver = undefined
        this.currentOrganizationMembership = undefined
        this.currentUserOrganizations = undefined
        this.currentOrganizationEventsTotal = undefined
        this.currentOrganizationEventsNextPage = undefined
        this.currentOrganizationStats = undefined
        this.currentOrganisationPack = undefined
        this.draftsEvents = 0

        localStorage.clear()

        this.router.navigate(['/home'])
    }

    createUser(input: CreateUserInput) {
        return this.httpClient
            .post<User>(`${environment.apiV3Url}/register`, input)
            .pipe(tap(user => {}))
    }

    async restoreAuth() {
        const authData = this.jwtService.getAuthData()

        if (!authData) {
            return
        }

        this.token = authData.token

        const expiresIn = this.expiresIn(authData.expirationDate)

        if (expiresIn > 0) {
            this.loginUser(this.token)
            this.isAuth = true
            this.token = authData.token
            this.currentUser = authData.user

            this.setAuthTimer(expiresIn / 1000)

            this.favoriteService.getFavorites().subscribe()

            this.isAuth = true
        } else {
            this.logUserOut()
        }
    }

    changeCurrentUser(currentUser: any) {
        this.jwtService.saveCurrentUser(currentUser)
        this.currentUser = currentUser
    }

    expiresIn(date: Date) {
        const now = new Date()
        return date.getTime() - now.getTime()
    }

    private setAuthTimer(seconds: number) {
        this.tokenTimer = setTimeout(() => {
            this.logUserOut()
        }, seconds * 1000)
    }

    socialLogin(provider, body) {
        return this.httpClient.post(`${environment.apiV3Url}/login/${provider}`, body).pipe(
            switchMap((res: any) => {
                const token = res.access_token
                this.loginUser(token)
                return of(this.currentUser)
            }),
            mergeMap(user => {
                return forkJoin({
                    currentUser: of(user)
                })
            }),
            map(data => {
                return data.currentUser
            }),
            catchError(err => {
                this.toastService.createError('Une erreur est survenue', 2000)
                return throwError(err)
            })
        )
    }

    manageCgu() {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.token
        })
        return this.httpClient
            .put(
                `${environment.apiV3Url}/me/terms`,
                { accepted_terms_date: this.currentUser.acceptedCGU },
                { headers }
            )
            .pipe(
                map(res => {
                    return res
                })
            )
    }

    deleteUser() {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.token
        })
        return this.httpClient.delete(`${environment.apiV3Url}/me`, { headers }).pipe(
            map(
                (response: any) => {
                    this.logUserOut()
                    return response
                },
                err => {
                    return err
                }
            )
        )
    }

    // From Annonceurs

    getMembers(organizationId: any) {
        // return of([])
        return this.httpClient
            .get<MGOrganizationMember[]>(
                `${environment.apiV3Url}/organizations/${organizationId}/members`
            )
            .pipe(
                map(members => {
                    return members
                })
            )
    }

    getOrganizationEvents(page = 0) {
        return this.httpClient
            .get(
                `${environment.apiV3Url}/organizations/${this.currentOrganization.id}/events?p=${page}`
            )
            .pipe(
                map((res: any) => {
                    this.currentOrganizationEventsNextPage = res.nextPage
                    return res
                })
            )
    }

    changeOrganization(organization: MGOrganization) {
        this.currentOrganization = organization
        localStorage.setItem('margaret_current_org_id', organization.id.toString())

        if (organization.subscriptionDate) {
            const subDate = parseISO(this.currentOrganization.subscriptionDate)
            const today = new Date()
            this.currentOrganizationSubscriptionDistance = formatDistance(subDate, today, {
                locale: fr
            })
        }

        this.organizationService.getOrganization(organization.id).subscribe(organization => {
            this.currentOrganizationStats = organization.events
            this.currentOrganisationPack = organization.membershipLabel
        })

        return this.getOrganizationEvents().pipe(
            mergeMap(res => {
                this.currentOrganizationEventsTotal = res.total
                this.currentOrganizationEventsCancelled = res.content.cancelled
                this.currentOrganizationEventsOver = res.content.over
                this.currentOrganizationEvents = res.content.published

                this.draftsEvents = 0
                this.currentOrganizationEvents.map(event => {
                    if (event.status == 'DRAFT') {
                        this.draftsEvents += 1
                    }
                })
                return this.getMembers(organization.id)
            }),
            map((members: MGOrganizationMember[]) => {
                return (this.currentOrganizationMembers = members)
            })
        )
    }

    // From Annonceurs

    createOrganization(formData: FormData): Observable<MGOrganization> {
        // formData.forEach((value: FormDataEntryValue, key: string) => {
        //     console.log(value, key)
        // })
        return this.httpClient
            .post<MGOrganization>(`${environment.apiV3Url}/organizations`, formData)
            .pipe(
                map(newOrganization => {
                    // CHECK: the api doesn't return the stats
                    newOrganization.stats = {
                        seen: 0,
                        subscribed: 0
                    }
                    // CHECK: the api doesn't return the role
                    newOrganization.role = 'RESPONSABLE'

                    this.currentOrganizationEvents = []
                    this.currentUserOrganizations.push(newOrganization)
                    this.currentOrganization =
                        this.currentUserOrganizations[this.currentUserOrganizations.length - 1]
                    return newOrganization
                })
            )
    }

    destroyOrganization(id: number) {
        return this.httpClient.delete(`${environment.apiV3Url}/organizations/${id}`).pipe(
            map(res => {
                const idx = this.currentUserOrganizations.findIndex(organization => {
                    return organization.id === id
                })
                this.currentUserOrganizations.splice(idx, 1)
                if (this.currentUserOrganizations.length > 0) {
                    this.currentOrganization = this.currentUserOrganizations[0]
                }
                return res
            })
        )
    }

    // getOrganizations() {
    //     return this.httpClient.get(`${environment.advertisersApiUrl}/organizations`).pipe(
    //         map((organizations: any) => {
    //             this.currentUserOrganizations = organizations
    //             return organizations
    //         })
    //     )
    // }

    getOrganizations() {
        return this.httpClient.get(`${environment.apiV3Url}/organizations`).pipe(
            map((organizations: any) => {
                this.currentUserOrganizations = organizations
                this.currentOrganization = organizations[0]
                return organizations
            })
        )
    }

    updateOrganization(formData: FormData): Observable<MGOrganization> {
        return this.httpClient
            .put<MGOrganization>(`${environment.apiV3Url}/organizations`, formData)
            .pipe(
                map(organizationUpdated => {
                    const idx = this.currentUserOrganizations.findIndex(organization => {
                        return organization.id === organizationUpdated.id
                    })
                    // organizationUpdated.stats = stats // the API doesn't return the stats
                    this.currentUserOrganizations[idx] = organizationUpdated
                    this.currentOrganization = organizationUpdated
                    return organizationUpdated
                })
            )
    }

    create(formData: FormData, type?: string) {
        return this.httpClient
            .post(
                `${environment.apiV3Url}/organizations/${this.currentOrganization.id}/events`,
                formData
            )
            .pipe(
                map(
                    (response: MGEvent) => {
                        this.currentOrganizationEvents.push(response)
                        return response
                    },
                    err => {
                        return err
                    }
                )
            )
    }

    // TODO: type it
    update(eventId, formData) {
        return this.httpClient
            .put(
                `${environment.apiV3Url}/organizations/${this.currentOrganization.id}/events/${eventId}`,
                formData
            )
            .pipe(
                map((data: any) => {
                    // const editedEvent = this.currentOrganizationEvents.find(event => {
                    //     return event.id == data.id
                    // })
                    // if (editedEvent) {
                    //     const category = this.categoryService.categories.find(category => {
                    //         return category.id == data.category.id
                    //     })
                    //     data.category = {
                    //         id: category.id,
                    //         label: category.label
                    //     }
                    //     const idx = this.currentOrganizationEvents.indexOf(editedEvent)
                    //     this.currentOrganizationEvents.splice(idx, 1, data)
                    // }

                    return data
                })
            )
    }

    updateEventStatus(eventId: number, status: string) {
        return this.httpClient
            .put(
                `${environment.apiV3Url}/organizations/${this.currentOrganization.id}/events/${eventId}/status`,
                { status }
            )
            .pipe(
                map((data: any) => {
                    return data
                })
            )
    }

    delete(eventId: number, organizationId: number) {
        return this.httpClient
            .delete(`${environment.apiV3Url}/organizations/${organizationId}/events/${eventId}`)
            .pipe(
                map(res => {
                    return res
                })
            )
    }

    logoutDevice(token: string) {
        return this.httpClient.get(`${environment.apiV3Url}/logout/${token}`).pipe(
            map(data => {
                return data
            })
        )
    }
}
