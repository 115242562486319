import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AuthService } from '../services/auth.service'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.isAuth) {
            req = this.addAuthenticationHeader(req)
        }
        return next.handle(req)
    }

    private addAuthenticationHeader(req: HttpRequest<any>) {
        const token = this.authService.getMargaretToken()
        return req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`)
        })
    }
}
