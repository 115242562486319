import { Injectable } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { environment } from '../../../../shared/environments/environment'

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    getYear(endDate: Date) {
        throw new Error('Method not implemented.')
    }
    format(startDate: Date, arg1: string): string {
        throw new Error('Method not implemented.')
    }
    compareDesc(endDate: Date, startDate: Date) {
        throw new Error('Method not implemented.')
    }
    constructor(private meta: Meta) {}

    imageUrl(array, from?: string) {
        return array
    }

    date2MaskedString(date: Date) {
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        return `${day < 10 ? '0' : ''}${day}${month < 10 ? '0' : ''}${month}${year}`
    }

    time2MaskedString(date: Date) {
        const hours = date.getHours()
        const minutes = date.getMinutes()
        return `${hours < 10 ? '0' : ''}${hours}${minutes < 10 ? '0' : ''}${minutes}`
    }

    maskedString2ISODate(stringDate: string, stringTime?: string) {
        const date = +stringDate.substring(0, 2)
        const month = +stringDate.substring(2, 4)
        const year = +stringDate.substring(4)

        let newDate: Date

        if (stringTime) {
            const hour = +stringTime.substring(0, 2)
            const minute = +stringTime.substring(2)
            newDate = new Date(year, month - 1, date, hour, minute)
        } else {
            newDate = new Date(year, month - 1, date)
        }
        return newDate
    }

    buildMetaTags(route: string, title: string, description: string, image?: string) {
        this.meta.updateTag({ name: 'title', content: title })
        this.meta.updateTag({ name: 'description', content: description })
        this.meta.updateTag({ name: 'site_name', content: 'Margaret' })
        this.meta.updateTag({ name: 'author', content: 'margaret.fr' })
        this.meta.updateTag({
            property: 'og:url',
            content: `${environment.shareRootUrl}${route}`
        })
        this.meta.updateTag({ property: 'og:locale', content: 'fr_FR' })
        this.meta.updateTag({ property: 'og:title', content: title })
        this.meta.updateTag({ property: 'og:type', content: 'article' })
        this.meta.updateTag({
            property: 'og:image',
            content: image ? image : `${environment.shareImageUrl}`
        })
        this.meta.updateTag({ property: 'og:description', content: description })
        this.meta.updateTag({ property: 'fb:app_id', content: environment.facebookAppId })
        this.meta.updateTag({ name: 'twitter:creator', content: 'Margaret' })
        this.meta.updateTag({ name: 'twitter:card', content: 'summary' })
        this.meta.updateTag({ name: 'twitter:site', content: environment.twitterSite })
        this.meta.updateTag({ name: 'twitter:url', content: `${environment.shareRootUrl}${route}` })
        this.meta.updateTag({ name: 'twitter:title', content: title })
        this.meta.updateTag({ name: 'twitter:description', content: description })
        this.meta.updateTag({
            name: 'twitter:image',
            content: image ? image : `${environment.shareImageUrl}`
        })
        // return [
        //     { name: 'title', content: title },
        //     { name: 'description', content: description },
        //     { name: 'site_name', content: 'Margaret' },
        //     { name: 'author', content: 'margaret.fr' },
        //     {
        //         property: 'og:url',
        //         content: `${environment.shareRootUrl}${route}`
        //     },
        //     { property: 'og:locale', content: 'fr_FR' },
        //     { property: 'og:title', content: title },
        //     { property: 'og:type', content: 'article' },
        //     {
        //         property: 'og:image',
        //         content: image ? image : `${environment.shareImageUrl}`
        //     },
        //     { property: 'og:description', content: description },
        //     { property: 'fb:app_id', content: environment.facebookAppId },

        //     { name: 'twitter:creator', content: 'Margaret' },
        //     { name: 'twitter:card', content: 'summary' },
        //     { name: 'twitter:site', content: environment.twitterSite },
        //     { name: 'twitter:url', content: `${environment.shareRootUrl}${route}` },
        //     { name: 'twitter:title', content: title },
        //     { name: 'twitter:description', content: description },
        //     {
        //         name: 'twitter:image',
        //         content: image ? image : `${environment.shareImageUrl}`
        //     }
        // ]
    }
}
