import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { IonicModule, ModalController } from '@ionic/angular'
import { MGAd } from '../../../../../../shared/models/ad.model'

@Component({
    selector: 'app-ad',
    templateUrl: './ad.component.html',
    styleUrls: ['./ad.component.scss'],
    standalone: true,
    imports: [CommonModule, IonicModule]
})
export class AdComponent implements OnInit {
    ad: MGAd

    constructor(private modalController: ModalController) {}

    ngOnInit() {}

    onClose(event) {
        event.preventDefault()
        this.modalController.dismiss()
    }

    onOpenLink() {
        window.open(this.ad.link, '_blank')
        this.modalController.dismiss()
    }

    onClick(event, action: string) {
        event.preventDefault()
        if (action === 'close') {
            this.onClose(event)
        } else if (action === 'open') {
            this.onOpenLink()
        }
    }
}
