import { NgIf } from '@angular/common'
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { IonicModule, ModalController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth.service'
import { SessionService } from 'src/app/services/session.service'
import { UserService } from 'src/app/services/user.service'
import { PasswordNewComponent } from '../password-new/password-new.component'
import { PasswordResetComponent } from '../password-reset/password-reset.component'
import { SignupPage } from '../signup/signup.page'
import { OmniComponent } from './omni/omni.component'

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        NgIf,
        OmniComponent,
        SignupPage,
        PasswordResetComponent,
        PasswordNewComponent
    ]
})
export class AuthComponent implements OnInit, AfterViewInit, OnDestroy {
    authPage: string

    constructor(
        private modalController: ModalController,
        public userSrv: UserService,
        public sessionSrv: SessionService,
        private authSrv: AuthService
    ) {}

    ngOnInit() {}

    ngAfterViewInit(): void {
        // if (this.authPage) {
        //     this.sessionSrv.authPage = undefined
        // }
    }

    ngOnDestroy(): void {
        this.sessionSrv.authPage = 'omni'
        this.sessionSrv.askingCode = false
        // this.sessionSrv.onboarding = false
    }

    onClose() {
        this.sessionSrv.authPage = 'omni'
        this.sessionSrv.askingCode = false
        // this.sessionSrv.onboarding = false
        this.modalController.dismiss()
        this.authSrv.doubleAuth = false
        this.authSrv.mfa = ''
        this.authSrv.marker = ''
    }

    // onToggle(page: string) {
    //     this.authPage = page
    // }

    getTitle(): string {
        let title = null
        switch (this.sessionSrv.authPage) {
            case 'omni':
                title = 'Identification'
                break

            case 'login':
                title = 'Connexion'
                break

            case 'signup':
                title = 'Inscription gratuite'
                break

            case 'password-reset':
                title = 'Mot de passe'
                break

            case 'password-new':
                title = 'Mot de passe'
                break

            default:
                break
        }
        return title
    }
}
