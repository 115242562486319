export const cleanNullOrUndefinedValues = obj => {
    const clonedObject = { ...obj }

    for (const [key, value] of Object.entries(clonedObject)) {
        if (value === undefined || value === null) {
            delete clonedObject[key]
        }
    }

    return clonedObject
}
