<div *ngIf="mode === 'edit'">Membre depuis {{ since() }}</div>
<ion-grid>
    <ion-row>
        <ion-col size="12">
            <div *ngIf="mode === 'edit'">
                <ion-segment [(ngModel)]="segmentButtonValue" mode="ios" class="segment-desktop">
                    <ion-segment-button value="profile">
                        <ion-label>Profil</ion-label>
                    </ion-segment-button>
                    <ion-segment-button value="passwordSegment">
                        <ion-label>Mot de passe</ion-label>
                    </ion-segment-button>
                </ion-segment>
            </div>

            <div *ngIf="mode === 'new' || segmentButtonValue === 'profile'">
                <div *ngIf="mode === 'edit'" class="mt-4">
                    <div class="text-2xl mb-4 text-stone-400 font-semibold">Votre avatar</div>
                    <div class="flex items-center gap-8">
                        <div class="shrink-0">
                            <img
                                class="h-24 w-24 object-cover rounded-full"
                                [src]="getAvatar()"
                                alt="Votre photo de profil"
                            />
                        </div>
                        <div class="block">
                            <ion-button (click)="onGetPhoto()" shape="round">
                                Changer la photo
                            </ion-button>
                        </div>
                    </div>
                    <div class="image-info">
                        Images acceptées {{ imagePreviewService.supportedFormats }}. Poids maxi : 10
                        Mo.
                    </div>

                    <ion-item>
                        <ion-toggle
                            color="primary"
                            mode="ios"
                            [(ngModel)]="authService.currentUser.mfa"
                            (ionChange)="onChangeDoubleAuth($event)"
                        >
                            Double authentification
                        </ion-toggle>
                    </ion-item>
                </div>
            </div>

            <ion-card color="orange" *ngIf="sessionSrv.onboarding">
                <ion-card-header>
                    <ion-card-subtitle>Étape 1 sur 3</ion-card-subtitle>
                    <ion-card-title>Inscription en tant qu'utilisateur</ion-card-title>
                </ion-card-header>
            </ion-card>

            <form [formGroup]="profileForm">
                <div *ngIf="mode === 'new' || segmentButtonValue === 'profile'">
                    <ion-label class="no-item-label">Genre</ion-label>
                    <ion-select
                        formControlName="gender"
                        interface="popover"
                        placeholder="Veuilez choisir un genre"
                    >
                        <ion-select-option
                            *ngFor="let gender of userService.genders"
                            [value]="gender.id"
                        >
                            {{ gender.frontLabel }}
                        </ion-select-option>
                    </ion-select>
                    <ion-label class="no-item-label">Date de naissance</ion-label>
                    <input
                        type="text"
                        mask="d0/M0/0000"
                        [leadZeroDateTime]="true"
                        formControlName="dob"
                        placeholder="Date de naissance"
                    />
                    <ion-label class="no-item-label">Prénom *</ion-label>
                    <ion-input
                        required
                        formControlName="firstname"
                        placeholder="Votre prénom"
                    ></ion-input>
                    <div
                        *ngIf="
                            profileForm.get('firstname').invalid &&
                            (profileForm.get('firstname').dirty ||
                                profileForm.get('firstname').touched)
                        "
                    >
                        <ion-text color="danger">Le prénom est obligatoire.</ion-text>
                    </div>
                    <ion-label class="no-item-label">Nom *</ion-label>
                    <ion-input
                        required
                        formControlName="lastname"
                        placeholder="Votre nom"
                    ></ion-input>
                    <div
                        *ngIf="
                            profileForm.get('lastname').invalid &&
                            (profileForm.get('lastname').dirty ||
                                profileForm.get('lastname').touched)
                        "
                    >
                        <ion-text color="danger">Le nom est obligatoire.</ion-text>
                    </div>
                    <ion-label class="no-item-label">E-mail *</ion-label>
                    <ion-input
                        required
                        type="email"
                        formControlName="email"
                        placeholder="Votre adresse e-mail"
                    ></ion-input>
                    <div
                        *ngIf="
                            profileForm.get('email').invalid &&
                            (profileForm.get('email').dirty || profileForm.get('email').touched)
                        "
                    >
                        <ion-text color="danger">L'adresse e-mail n'est pas valide.</ion-text>
                    </div>
                    <ion-label class="no-item-label">Téléphone</ion-label>
                    <ion-input
                        type="tel"
                        formControlName="phone"
                        placeholder="01234567890"
                    ></ion-input>
                    <!-- <div
                        *ngIf="
                            profileForm.get('phone').invalid &&
                            (profileForm.get('phone').dirty || profileForm.get('phone').touched)
                        "
                    >
                        <ion-text color="danger">Le numéro de téléphone est obligatoire.</ion-text>
                    </div> -->
                </div>
                <ion-label class="no-item-label" *ngIf="segmentButtonValue == 'profile'">
                    Ville
                </ion-label>
                <input
                    type="text"
                    ngx-gp-autocomplete
                    [options]="options"
                    (onAddressChange)="handleAddressChange($event)"
                    formControlName="location"
                    *ngIf="segmentButtonValue == 'profile'"
                />
                <div *ngIf="mode === 'new' || segmentButtonValue === 'passwordSegment'">
                    <div class="password" *ngIf="segmentButtonValue === 'passwordSegment'">
                        <ion-label class="no-item-label">Mot de passe actuel *</ion-label>
                        <ion-input
                            #password
                            [type]="oldPasswordType"
                            required
                            formControlName="oldPassword"
                            autocomplete="new-password"
                            placeholder="Mot de passe actuel *"
                        >
                            <ion-button
                                fill="clear"
                                slot="end"
                                aria-label="Show/hide"
                                (click)="onShowPassword('old')"
                            >
                                <ion-icon
                                    [name]="oldPasswordType == 'password' ? 'eye' : 'eye-off'"
                                    slot="icon-only"
                                    color="medium"
                                ></ion-icon>
                            </ion-button>
                        </ion-input>
                    </div>

                    <div class="password">
                        <ion-label class="no-item-label">
                            {{ mode == 'new' ? 'Mot de passe *' : 'Nouveau mot de passe *' }}
                        </ion-label>
                        <ion-input
                            #password
                            [type]="passwordType"
                            required
                            formControlName="password"
                            autocomplete="new-password"
                            [placeholder]="
                                mode == 'new' ? 'Mot de passe *' : 'Nouveau mot de passe *'
                            "
                        >
                            <ion-button
                                fill="clear"
                                slot="end"
                                aria-label="Show/hide"
                                (click)="onShowPassword('pass')"
                            >
                                <ion-icon
                                    [name]="passwordType == 'password' ? 'eye' : 'eye-off'"
                                    slot="icon-only"
                                    color="medium"
                                ></ion-icon>
                            </ion-button>
                        </ion-input>
                    </div>

                    <app-password-check
                        [password]="profileForm.value.password"
                        (passwordOk)="isPasswordOk($event)"
                    ></app-password-check>

                    <div class="password">
                        <ion-label class="no-item-label">Confirmation *</ion-label>
                        <ion-input
                            [type]="passwordConfirmType"
                            required
                            formControlName="passwordConfirmation"
                            placeholder="Confirmation du mot de passe *"
                            autocomplete="new-password"
                        >
                            <ion-button
                                fill="clear"
                                slot="end"
                                aria-label="Show/hide"
                                (click)="onShowPassword('conf')"
                            >
                                <ion-icon
                                    [name]="passwordConfirmType == 'password' ? 'eye' : 'eye-off'"
                                    slot="icon-only"
                                    color="medium"
                                ></ion-icon>
                            </ion-button>
                        </ion-input>
                    </div>
                    <div
                        *ngIf="
                            profileForm.get('passwordConfirmation').invalid &&
                            (profileForm.get('passwordConfirmation').dirty ||
                                profileForm.get('passwordConfirmation').touched)
                        "
                    >
                        <ion-text color="danger">
                            La confirmation du mot de passe est obligatoire.
                        </ion-text>
                    </div>
                    <div *ngIf="mode === 'new' || segmentButtonValue === 'passwordSegment'">
                        <div class="my-4">
                            <div *ngFor="let policy of policies">
                                <ion-text color="danger" *ngIf="!policy.valid">
                                    {{ policy.text }}
                                </ion-text>
                            </div>
                        </div>
                    </div>
                    <ion-grid class="ion-no-padding" *ngIf="mode === 'new'">
                        <ion-row>
                            <ion-col qize="12">
                                <ion-item lines="none" class="ion-item-clear">
                                    <ion-checkbox
                                        color="primary"
                                        justify="space-between"
                                        formControlName="acceptedCGU"
                                    >
                                        <ion-text class="ion-text-wrap">
                                            J'accepte les conditions générales de l'application
                                            Margaret
                                        </ion-text>
                                    </ion-checkbox>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col size="12" class="ion-text-center">
                                <ion-button fill="clear" (click)="openCGU()">
                                    (lire les conditions)
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </div>
                <ion-button
                    expand="block"
                    color="orange"
                    *ngIf="!loading"
                    [disabled]="!profileForm.valid || !profileForm.value.acceptedCGU"
                    (click)="mode === 'new' ? register() : update()"
                >
                    {{ mode === 'new' ? "S'enregistrer" : 'Mettre à jour' }}
                </ion-button>
                <ion-button expand="block" color="orange" *ngIf="loading" [disabled]="loading">
                    {{ mode === 'new' ? 'Création en cours...' : 'Mise à jour en cours...' }}
                </ion-button>
                <ion-button
                    expand="block"
                    fill="clear"
                    color="medium"
                    (click)="logout()"
                    *ngIf="mode === 'edit'"
                >
                    Déconnexion
                </ion-button>
                <ion-button
                    (click)="onDestroy()"
                    expand="block"
                    fill="clear"
                    color="danger"
                    *ngIf="mode === 'edit'"
                >
                    Supprimer mon compte
                </ion-button>
            </form>
        </ion-col>
    </ion-row>
</ion-grid>
