import { Injectable } from '@angular/core'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { ToastController } from '@ionic/angular'
import { UserService } from './user.service'

interface MGPhoto {
    index: number
    base64: string
    url: string
    updated: boolean
}

@Injectable({ providedIn: 'root' })
export class ImagePreviewService {
    files: File[] = []
    imageURI: string
    currentBase64Logo: string
    currentBase64Cover: string
    currentBase64Array: MGPhoto[] = []
    currentBase64NewEventArray: MGPhoto[] = []
    imageTooHeavy: boolean
    supportedFormats: string

    constructor(private toastController: ToastController, private userSrv: UserService) {
        this.imageTooHeavy = undefined
        this.supportedFormats = 'JPEG, PNG et WEBP'
        this.photosReset()
        this.photosNewEventReset()
    }

    photosReset() {
        this.currentBase64Array = []
        for (let i = 0; i < 6; i++) {
            this.currentBase64Array.push({ index: i, base64: '', url: '', updated: false })
        }
    }

    photosNewEventReset() {
        this.currentBase64NewEventArray = []
        for (let i = 0; i < 7; i++) {
            this.currentBase64NewEventArray.push({ index: i, base64: '', url: '', updated: false })
        }
    }

    getNewEventPhtosCount() {
        return this.currentBase64NewEventArray.filter(photo => photo.base64 !== '').length
    }

    addImage(target: HTMLInputElement) {
        const file = target.files[0]

        if (!file) {
            return this.files
        }

        this.files.push(file)

        return this.files
    }

    getFiles() {
        return [...this.files]
    }

    async getFileFromBase64(base64: string) {
        const response = await fetch(base64)
        const blob = await response.blob()
        if (blob.size >= 10000000) {
            const toast = await this.toastController.create({
                message: `L'une de vos images est trop lourde...`,
                color: 'danger',
                duration: 5000
            })
            await toast.present()
            this.imageTooHeavy = true
            // return
        }
        const filename: string = new Date().toString()
        return new File([blob], filename, { type: 'image/jpeg' })
    }

    async getImageFileFormat(isCover = false) {
        this.imageTooHeavy = undefined

        if (!isCover && this.currentBase64Logo === undefined) return undefined

        if (!this.currentBase64Cover && isCover) {
            return
        }
        const response = isCover
            ? await fetch(this.currentBase64Cover)
            : await fetch(this.currentBase64Logo)

        if (response.status === 404) return undefined

        const blob = await response.blob()
        if (blob.size >= 10000000) {
            const toast = await this.toastController.create({
                message: `L'une de vos images est trop lourde...`,
                color: 'danger',
                duration: 5000
            })
            await toast.present()
            this.imageTooHeavy = true
            return
        }
        this.imageTooHeavy = false
        const filename: string = new Date().toString()
        return new File([blob], filename, { type: 'image/jpeg' })
    }

    async getMainPhoto() {
        this.imageTooHeavy = undefined
        const response = await fetch(this.currentBase64NewEventArray[0].base64)
        const blob = await response.blob()
        if (blob.size / 1048576 >= 10) {
            const toast = await this.toastController.create({
                message: `Votre image principale est trop lourde...`,
                color: 'danger',
                duration: 5000
            })
            await toast.present()
            this.imageTooHeavy = true
            return
        }
        this.imageTooHeavy = false
        const filename: string = new Date().toString()
        return new File([blob], filename, { type: 'image/jpeg' })
    }

    async onGetPhoto(isCover = false) {
        const image = await Camera.getPhoto({
            source: CameraSource.Photos,
            quality: 100,
            allowEditing: false,
            resultType: CameraResultType.Base64,
            promptLabelPhoto: 'Choisir une photo',
            promptLabelPicture: 'Prendre une photo',
            promptLabelCancel: 'Annuler'
        })
        if (image) {
            if (isCover) {
                this.currentBase64Cover = `data:image/${image.format};base64,` + image.base64String
            } else {
                this.currentBase64Logo = `data:image/${image.format};base64,` + image.base64String
            }
            return image
        }
    }

    async onAddPhoto(index) {
        const image = await Camera.getPhoto({
            source: CameraSource.Photos,
            quality: 100,
            allowEditing: false,
            resultType: CameraResultType.Base64,
            promptLabelPhoto: 'Choisir une photo',
            promptLabelPicture: 'Prendre une photo',
            promptLabelCancel: 'Annuler'
        })
        if (image) {
            this.currentBase64Array[index] = {
                index,
                base64: `data:image/${image.format};base64,` + image.base64String,
                url: '',
                updated: true
            }
            return image
        }
    }

    async onAddNewEventPhoto(index) {
        const image = await Camera.getPhoto({
            source: CameraSource.Photos,
            quality: 100,
            allowEditing: false,
            resultType: CameraResultType.Base64,
            promptLabelPhoto: 'Choisir une photo',
            promptLabelPicture: 'Prendre une photo',
            promptLabelCancel: 'Annuler'
        })
        if (image) {
            this.currentBase64NewEventArray[index] = {
                index,
                base64: `data:image/${image.format};base64,` + image.base64String,
                url: '',
                updated: true
            }
            return image
        }
    }
}
