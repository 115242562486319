import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { AuthService } from '../services/auth.service'
import { ToastService } from '../services/toast.service'

@Injectable({
    providedIn: 'root'
})
export class IsProGuard  {
    constructor(
        private authService: AuthService,
        private router: Router,
        private toastService: ToastService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // TODO: fix
        return true
    }
}
