import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../shared/environments/environment'
import { MGOrganization } from '../../../../shared/models/organization'
import { MGOrganizationMember } from '../../../../shared/models/organization-member'

export interface OrganizationEvent {
    accessibility: boolean
    active: boolean
    address_gps_lat_cache: number
    address_gps_lon_cache: number
    address_id: number
    category_id: number
    createdAt: string
    created_by: number
    description: string
    dogs: boolean
    family: boolean
    finish_at: string
    free: boolean
    id: number
    img: string
    indoor: boolean
    keywords: string
    name: string
    organization_id: number
    phone: string
    publication_date?: string
    reported: boolean
    saved?: number
    seen?: number
    start_at: string
    status_id: number
    uniqueid: string
    updatedAt: string
}

export interface GetOrganizationData {
    active: boolean
    avatar?: string
    cover: string
    createdAt?: string
    defaultEventAddress: {
        city: string
        city_id: number
        country_iso_code: string
        gps_lat: number
        gps_lon: number
        id: number
        line_address: string
        name: string
        street: string
        zipcode: string
    }
    defaut_event_address: number
    description: string
    draft_events: OrganizationEvent[]
    published_events: any // OrganizationEvent[]
    terminated_events: OrganizationEvent[]
    facebook: string
    has_subscribed: boolean
    id: number
    logo?: string
    mail: string
    name: string
    organizer: {
        accepted_cgv_date?: string
        accepted_gdpr_date?: string
        accepted_terms_date?: string
        active: boolean
        avatar?: string
        city_id?: number
        double_auth: boolean
        double_auth_token?: string
        firstname: string
        id: number
        lastname: string
        mail: string
        organizer_active?: boolean
        password: string
        password_chosen: boolean
        password_modified_at?: string
        phone: string
        uniqueid: string
    }
    organizer_id: number
    phone: string
    pics?: string[]
    rna?: string
    siren?: string
    siret?: string
    twitter: string
    type: string
    uniqueid: string
    video?: string
    website: string
}

@Injectable({ providedIn: 'root' })
export class OrganizationService {
    MARGARET_ORG_ID = 'b9bb64a6c86681ad5887b76d5df18bf4dff3899b'

    constructor(private httpClient: HttpClient) {}

    // FIXME : duplicated in event.service.ts
    getOrganization(organizationId: string | number, page = 0): Observable<MGOrganization> {
        return this.httpClient
            .get<MGOrganization>(
                `${environment.apiV3Url}/organizations/${organizationId}?p=${page}`
            )
            .pipe(
                map(organization => {
                    return organization
                })
            )
    }

    getOrganizationEvents(organizationUniqueId: string): Observable<OrganizationEvent[]> {
        return this.httpClient
            .get<OrganizationEvent[]>(
                `${environment.apiV3Url}/me/bookmarks/organizations/${organizationUniqueId}`
            )
            .pipe(
                map(events => {
                    return events
                })
            )
    }

    prepare(organizationId: string | number, gpsLat: number, gpsLon: number) {
        return this.httpClient
            .get(
                `${environment.apiV3Url}/organizations/${organizationId}/events/prepare?gpsLat=${gpsLat}&gpsLon=${gpsLon}`
            )
            .pipe(
                map(data => {
                    return data
                })
            )
    }

    subscribe(organizationUid: string) {
        return this.httpClient
            .post(`${environment.apiV3Url}/me/bookmarks/organizations/${organizationUid}`, {})
            .pipe(
                map(data => {
                    return data
                })
            )
    }

    unsubscribe(organizationUid: string) {
        return this.httpClient
            .delete(`${environment.apiV3Url}/me/bookmarks/organizations/${organizationUid}`, {})
            .pipe(
                map(data => {
                    return data
                })
            )
    }

    // From Annonceurs

    prefill(identifier) {
        return this.httpClient
            .get(`${environment.apiV3Url}/organizations/prefill?id=${identifier}`)
            .pipe(
                map(data => {
                    return data
                })
            )
    }

    updateMemberRole(organizationId: number, user) {
        return this.httpClient
            .put(`${environment.apiV3Url}/organizations/${organizationId}/members`, user)
            .pipe(
                map(data => {
                    return data
                })
            )
    }

    removeMember(organizationId: number, memberId: string) {
        return this.httpClient
            .delete(`${environment.apiV3Url}/organizations/${organizationId}/members/${memberId}`)
            .pipe(
                map(data => {
                    return data
                })
            )
    }

    inviteMember(organizationId, member) {
        return this.httpClient
            .post<MGOrganizationMember>(
                `${environment.apiV3Url}/organizations/${organizationId}/members`,
                member
            )
            .pipe(
                map(data => {
                    return data
                })
            )
    }
}
