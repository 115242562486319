import { NgIf } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { Router, RouterModule } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { SessionService } from 'src/app/services/session.service'
import { UserService } from 'src/app/services/user.service'
import { environment } from '../../../../../../shared/environments/environment'

@Component({
    selector: 'app-footer',
    templateUrl: 'footer.component.html',
    styleUrls: ['footer.component.scss'],
    standalone: true,
    imports: [IonicModule, NgIf, RouterModule]
})
export class FooterComponent implements OnInit {
    @Input() home = false
    currentYear: number
    appVersion = environment.desktopVersion

    constructor(
        private router: Router,
        public sessionSrv: SessionService,
        public userSrv: UserService
    ) {}

    // async ngOnInit() {
    //     this.deviceInfo = await Device.getInfo()
    // }

    ngOnInit() {
        this.currentYear = new Date().getFullYear()
    }

    // goToLegal() {
    //     this.router.navigate(['/legal'])
    // }

    goToCgu() {
        this.router.navigate(['/cgu'])
    }

    goToCgs() {
        window.open('assets/pdf/CGS.pdf', '_blank')
    }

    goToSocial(network: string) {
        switch (network) {
            case 'instagram':
                window.open('https://www.instagram.com/margaretapp_/')
                break

            case 'tiktok':
                window.open('https://www.tiktok.com/@margaretapp_?lang=fr')
                break

            case 'facebook':
                window.open('https://www.facebook.com/Margaret-102072052621586')
                break

            case 'linkedin':
                window.open('https://www.linkedin.com/company/margaretapplication')
                break

            default:
                break
        }
    }
}
