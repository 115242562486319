<ion-header>
    <ion-toolbar color="primary" style="border-radius: 20px 20px 0px 0px">
        <ion-title></ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onClose()">Fermer</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="m-10">
        <ion-button (click)="onCGU()" expand="block">
            Toutes nos Conditions Générales d'Utilisation en PDF
        </ion-button>
        <ion-button (click)="onCGS()" expand="block">
            Toutes nos Conditions Générales de service en PDF
        </ion-button>
    </div>
</ion-content>
<ion-footer>
    <ion-toolbar color="primary" class="ion-text-center">
        <ion-title>Conditions générales de l'application Margaret</ion-title>
    </ion-toolbar>
</ion-footer>
