<ion-footer>
    <ion-toolbar color="primary">
        <ion-grid>
            <ion-row *ngIf="sessionSrv.responsive">
                <ion-col size="12" class="ion-text-center">
                    <ion-text color="light">
                        Pour profiter pleinement de l'expérience Margaret
                    </ion-text>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col
                    [size]="sessionSrv.responsive ? 12 : 3"
                    [class]="
                        sessionSrv.responsive
                            ? 'flex ion-justify-content-center ion-align-items-center'
                            : 'flex ion-justify-content-start ion-align-items-center pl-5'
                    "
                >
                    <div>
                        <a href="https://apps.apple.com/us/app/margaret/id1636255228">
                            <img
                                src="assets/img/stores/apple.png"
                                class="pr-3"
                                style="height: 30px"
                            />
                        </a>
                    </div>
                    <div>
                        <a href="https://play.google.com/store/apps/details?id=com.margaretapp.app">
                            <img src="assets/img/stores/google.png" style="height: 30px" />
                        </a>
                    </div>
                </ion-col>
                <ion-col size="6" class="ion-text-center" *ngIf="!sessionSrv.responsive">
                    <ion-button routerLink="/about" color="mauve" fill="clear" size="small">
                        À propos
                    </ion-button>
                    <ion-button routerLink="/contact" color="mauve" fill="clear" size="small">
                        Contactez-nous
                    </ion-button>
                    <!-- <ion-button (click)="goToLegal()" color="mauve" fill="clear" size="small">
                        Mentions légales
                    </ion-button> -->
                    <ion-button (click)="goToCgu()" color="mauve" fill="clear" size="small">
                        C.G.U.
                    </ion-button>
                    <ion-button (click)="goToCgs()" color="mauve" fill="clear" size="small">
                        C.G.S.
                    </ion-button>
                    <!-- <ion-button routerLink="/faq" color="mauve" fill="clear" size="small">
                        F.A.Q.
                    </ion-button> -->
                    <ion-button routerLink="/suggestions" color="mauve" fill="clear" size="small">
                        Boîte à idées
                    </ion-button>
                    <p>
                        <ion-text color="mauve">
                            © {{ currentYear }} SAS Margaret - v {{ appVersion }}
                        </ion-text>
                    </p>
                </ion-col>
                <ion-col
                    size="3"
                    class="flex justify-end items-center"
                    *ngIf="!sessionSrv.responsive"
                >
                    <ion-button
                        color="light"
                        fill="clear"
                        size="small"
                        (click)="goToSocial('instagram')"
                    >
                        <ion-icon slot="icon-only" name="logo-instagram"></ion-icon>
                    </ion-button>
                    <ion-button
                        color="light"
                        fill="clear"
                        size="small"
                        (click)="goToSocial('tiktok')"
                    >
                        <ion-icon slot="icon-only" name="logo-tiktok"></ion-icon>
                    </ion-button>
                    <ion-button
                        color="light"
                        fill="clear"
                        size="small"
                        (click)="goToSocial('facebook')"
                    >
                        <ion-icon slot="icon-only" name="logo-facebook"></ion-icon>
                    </ion-button>
                    <ion-button
                        color="light"
                        fill="clear"
                        size="small"
                        (click)="goToSocial('linkedin')"
                    >
                        <ion-icon slot="icon-only" name="logo-linkedin"></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>
