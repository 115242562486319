import { Component, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { IonicModule, ModalController } from '@ionic/angular'
import { AuthService } from 'src/app/services/auth.service'
import { LegalUseComponent } from '../../components/legal-use/legal-use.component'

@Component({
    selector: 'app-accept-cgu-modal',
    templateUrl: './accept-cgu-modal.component.html',
    styleUrls: ['./accept-cgu-modal.component.scss'],
    standalone: true,
    imports: [IonicModule, FormsModule]
})
export class AcceptCguModalComponent implements OnInit {
    cgu: boolean

    constructor(private modalController: ModalController, private authSrv: AuthService) {}

    ngOnInit() {
        this.cgu = false
    }

    async openCGU() {
        const modal = await this.modalController.create({
            component: LegalUseComponent,
            cssClass: 'legal-use-modal'
        })

        await modal.present()
    }

    onAccept() {
        this.authSrv.currentUser.acceptedCGU = true
        this.authSrv.manageCgu().subscribe(() => {
            this.modalController.dismiss({ cgu: this.cgu })
        })
    }

    onCancel() {
        this.authSrv.currentUser.acceptedCGU = false
        this.authSrv.manageCgu().subscribe(() => {
            this.modalController.dismiss({ cgu: false })
        })
    }
}
