import { BreakpointObserver } from '@angular/cdk/layout'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'

@Injectable({
    providedIn: 'root'
})
export class HomeGuard {
    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        private bpo: BreakpointObserver,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // if (isPlatformBrowser(this.platformId)) {
        //     this.bpo.observe(['(min-width: 768px)']).subscribe(result => {
        //         if (!result.matches) {
        //             // this.router.navigate([`${this.router.url}`])
        //             this.router.navigate([`/mobile`])
        //             return false
        //         }
        //         // else {
        //         //     return true
        //         // }
        //     })
        // }
        return true
    }
}
