import { isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { PlaceInfos } from '../../../../shared/models/google-map/place-infos.model'
import { PlaceKeys } from '../../../../shared/models/google-map/place-keys.model'
import { PlaceTypes } from '../../../../shared/models/google-map/place-types.model'
import { EventsObsserversService } from './events-observers.service'
import { SearchService } from './search.service'

@Injectable({ providedIn: 'root' })
export class GooglePlaceService {
    place: google.maps.places.PlaceResult
    placeInfos: PlaceInfos

    constructor(
        private searchService: SearchService,
        private eventsObsSrv: EventsObsserversService,
        @Inject(PLATFORM_ID) private platformId: string
    ) {}

    getPlaceAutocomplete(addressText: any): any {
        if (addressText) {
            if (isPlatformBrowser(this.platformId)) {
                const autocomplete = new google.maps.places.Autocomplete(
                    addressText.nativeElement,
                    {
                        componentRestrictions: { country: ['FR'] },
                        types: ['geocode']
                        // fields: ['address_component', 'formatted_address', 'name', 'geometry']
                    }
                )
                google.maps.event.addListener(autocomplete, 'place_changed', () => {
                    const place = autocomplete.getPlace()
                    this.place = place
                    this.placeInfos = this.getInfos(this.place)
                    this.searchService.gpsLat = null
                    this.searchService.gpsLon = null
                    this.searchService.city = this.placeInfos.city

                    this.searchService.searchOptions.lat = this.placeInfos.gps_lat
                    this.searchService.searchOptions.lon = this.placeInfos.gps_lng

                    if (this.searchService.rad == null) {
                        this.searchService.searchOptions.rad = 50
                    }

                    this.eventsObsSrv.publish('around-panel', true)
                })

                // GoogleMap add event listener on place_changed
                // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteService
            }
        }
    }

    getInfos(place, input?: HTMLInputElement): PlaceInfos {
        let city = null

        if (input?.value.split(' ').length == 3) {
            for (let i = 0; i < input.value.split(' ').length; i++) {
                const element = input.value.split(' ')[i]
                if (element.charAt(element.length - 1) == ',') {
                    city = element.replace(',', '')
                }
            }
        }

        let street: string

        const streetNumber = this.getPlaceInfo(place, 'street_number', 'long_name')
        const route = this.getPlaceInfo(place, 'route', 'long_name')

        street = streetNumber != undefined ? `${streetNumber}` : ''
        street = route != undefined ? `${street} ${route}` : ''

        return {
            name: place.name,
            gps_lat: place.geometry.location.lat(),
            gps_lng: place.geometry.location.lng(),
            street,
            city: city == null ? this.getPlaceInfo(place, 'locality', 'long_name') : city,
            zipcode: this.getPlaceInfo(place, 'postal_code', 'long_name'),
            country: this.getPlaceInfo(place, 'country', 'long_name'),
            countryISO: this.getPlaceInfo(place, 'country', 'short_name'),
            fullAddress: input?.value.split(' ').length == 3 ? input : place.formatted_address
        }
    }

    private getPlaceInfo(place, type: PlaceTypes, key: PlaceKeys): string | undefined {
        const infos = place.address_components.find(a => a.types.includes(type))

        if (!infos) {
            return undefined
        }

        return infos[key]
    }
}
