import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MGCategory } from '../../../../shared/models/category.model'

@Injectable({ providedIn: 'root' })
export class CategoryService {
    categories: MGCategory[]

    constructor(private httpClient: HttpClient) {}

    // getCategories() {
    //     return this.httpClient.get<Category[]>('assets/mockup/categories.dummy.json').pipe(
    //         map(data => {
    //             this.categories = data
    //             // this.categories.unshift({
    //             //     id: 10000,
    //             //     name: 'recents',
    //             //     label: 'Prochainement',
    //             //     keywords: '',
    //             //     disp_order: 0
    //             // })
    //         })
    //     )
    // }
}
