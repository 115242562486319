import { BreakpointObserver } from '@angular/cdk/layout'
import { isPlatformBrowser } from '@angular/common'
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core'
import { Router } from '@angular/router'
import { ModalController } from '@ionic/angular'
import { differenceInCalendarDays } from 'date-fns'
import { MGAd } from '../../../shared/models/ad.model'
import { AdComponent } from './core/components/ad/ad.component'
import { AuthService } from './services/auth.service'
import { CommonsService } from './services/commons.service'
import { SessionService } from './services/session.service'

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    previousUrl: string = null
    currentUrl: string = null
    isServer = true

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        public authService: AuthService,
        private commonsSrv: CommonsService,
        private modalController: ModalController,
        private router: Router,
        public sessionSrv: SessionService,
        private bpo: BreakpointObserver
    ) {}

    async ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.bpo.observe(['(min-width: 768px)']).subscribe(result => {
                if (result.matches) {
                    this.sessionSrv.responsive = false
                } else {
                    this.sessionSrv.responsive = true
                }
            })
        }

        this.commonsSrv.get().subscribe(commons => {
            if (
                !this.router.url.includes('subscription') &&
                !this.router.url.includes('eventpayment')
            ) {
                if (commons.ads.length > 0 && !this.sessionSrv.responsive) {
                    let showAd = true
                    if (localStorage.getItem('margaret_ad')) {
                        const date = new Date(localStorage.getItem('margaret_ad'))
                        if (differenceInCalendarDays(new Date(), date) > 1) {
                            localStorage.setItem('margaret_ad', new Date().toISOString())
                            showAd = true
                        } else {
                            showAd = false
                        }
                    } else {
                        localStorage.setItem('margaret_ad', new Date().toISOString())
                        showAd = true
                    }
                    if (showAd) {
                        const pub = commons.ads[0]
                        if (
                            pub.active
                            // &&
                            // compareAsc(new Date(), new Date(pub.startDate)) === 1 &&
                            // compareDesc(new Date(), new Date(pub.endDate)) === 1
                        ) {
                            this.presentModal(pub)
                        }
                    }
                }
            }
        })
        if (isPlatformBrowser(this.platformId)) {
            this.isServer = false
        }
        this.authService.restoreAuth()
    }

    async presentModal(ad: MGAd) {
        const modal = await this.modalController.create({
            component: AdComponent,
            componentProps: { ad },
            cssClass: 'ads-popover'
        })

        await modal.present()
    }
}
