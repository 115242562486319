import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, tap } from 'rxjs/operators'
import { environment } from '../../../../shared/environments/environment'
import { UpdateUserData } from '../../../../shared/models/user/update-user-data.model'

@Injectable({ providedIn: 'root' })
export class UserService {
    genders = [
        { id: 1, label: 'M', frontLabel: 'Homme' },
        { id: 2, label: 'F', frontLabel: 'Femme' },
        { id: 3, label: 'O', frontLabel: 'Autre' }
    ]

    constructor(private httpClient: HttpClient) {}

    activate(userId: string, key: string) {
        return this.httpClient
            .post(`${environment.apiV3Url}/users/activate`, {
                userId,
                key
            })
            .pipe(
                map(data => {
                    return data
                })
            )
    }

    getUser() {
        return this.httpClient.get(`${environment.apiV3Url}/me`).pipe(
            map(data => {
                return data
            })
        )
    }

    updatePassword(formData: FormData) {
        return this.update(formData)
    }

    updateProfile(formData: FormData) {
        return this.update(formData)
    }

    private update(formData: FormData) {
        return this.httpClient.put<UpdateUserData>(`${environment.apiV3Url}/me`, formData).pipe(
            tap(data => {
                return data
            })
        )
    }
}
