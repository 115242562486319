import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { ModalController, ToastController } from '@ionic/angular'
import { isBefore } from 'date-fns'
import { AuthComponent } from '../core/pages/auth/auth.component'
import { AuthService } from '../services/auth.service'
import { JwtService } from '../services/jwt.service'
import { SessionService } from '../services/session.service'

@Injectable({
    providedIn: 'root'
})
export class IsAuthGuard {
    constructor(
        private authService: AuthService,
        private router: Router,
        private jwtSrv: JwtService,
        private sessionSrv: SessionService,
        private modalController: ModalController,
        private toastController: ToastController
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authService.isAuth) {
            this.router.navigate(['/home'])
        }

        const expirationDateISO = this.jwtSrv.getAuthData().expirationDate
        const expirationDate = new Date(expirationDateISO)
        const expired = isBefore(expirationDate, new Date())

        if (expired) {
            this.authService.logout()
            this.router.navigate(['/home'])
            this.presentModal()
        }

        return this.authService.isAuth && !expired ? true : false
    }

    private async presentModal() {
        this.sessionSrv.authPage = 'omni'
        const modal = await this.modalController.create({
            component: AuthComponent,
            cssClass: 'full-popover',
            canDismiss: true
        })
        await modal.present()

        const toast = await this.toastController.create({
            message: `Votre session a expiré. Veuillez vous reconnecter.`,
            duration: 5000,
            color: 'danger'
        })
        await toast.present()
    }
}
