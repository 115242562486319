<ion-header style="padding: 0px !important; box-shadow: none">
    <ion-toolbar color="primary" *ngIf="!sessionSrv.searchBarKingSize">
        <ion-grid>
            <ion-row>
                <ion-col [size]="sessionSrv.responsive ? 12 : 3">
                    <div class="flex ion-justify-content-center ion-align-items-center">
                        <img
                            (click)="backToHome()"
                            src="/assets/img/margaret_logo_fcqve.png"
                            class="cursor-pointer"
                            style="max-width: 200px"
                        />
                    </div>
                </ion-col>
                <!-- <ion-col size="1" style="background-color: yellow"></ion-col> -->
                <ion-col
                    size="6"
                    class="flex ion-justify-content-center ion-align-items-center"
                    *ngIf="!sessionSrv.responsive"
                ></ion-col>
                <ion-col
                    size="3"
                    class="flex ion-align-items-center"
                    *ngIf="!sessionSrv.responsive"
                >
                    <ion-button (click)="onboarding()" expand="block" fill="solid" color="orange">
                        Publier un événement !
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
        <ion-buttons slot="end" *ngIf="!sessionSrv.responsive">
            <ion-button class="menu-icon" (click)="onFavorites()" *ngIf="authService.isAuth">
                <ion-col>
                    <ion-row style="display: block">
                        <ion-icon name="heart" color="secondary" class="menu-icon"></ion-icon>
                    </ion-row>
                    <ion-row><ion-text>Favoris</ion-text></ion-row>
                </ion-col>
            </ion-button>
            <ion-button class="menu-icon" (click)="onAlerts()" *ngIf="authService.isAuth">
                <ion-badge color="orange" mode="ios" *ngIf="alertSrv.eventsCount > 0">
                    {{ alertSrv.eventsCount }}
                </ion-badge>
                <ion-col>
                    <ion-row style="display: block">
                        <ion-icon
                            name="notifications"
                            color="secondary"
                            class="menu-icon"
                        ></ion-icon>
                    </ion-row>
                    <ion-row>
                        <ion-text>Alertes</ion-text>
                    </ion-row>
                </ion-col>
            </ion-button>
            <ion-chip (click)="onAuth($event)" color="mauve" class="desktop">
                <ion-label style="position: relative; top: 2px">
                    <ion-icon slot="icon-only" name="menu-outline" color="light"></ion-icon>
                </ion-label>

                <ion-avatar *ngIf="authService.currentUser?.avatar">
                    <img [src]="authService.currentUser?.avatar || '/assets/svg/circle-user.svg'" />
                </ion-avatar>
                <ion-avatar
                    *ngIf="!authService.currentUser?.avatar"
                    style="width: 100px; height: 45px"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        style="fill: white; height: 30px; position: relative; top: 5px"
                    >
                        <path
                            d="M406.5 399.6C387.4 352.9 341.5 320 288 320H224c-53.5 0-99.4 32.9-118.5 79.6C69.9 362.2 48 311.7 48 256C48 141.1 141.1 48 256 48s208 93.1 208 208c0 55.7-21.9 106.2-57.5 143.6zm-40.1 32.7C334.4 452.4 296.6 464 256 464s-78.4-11.6-110.5-31.7c7.3-36.7 39.7-64.3 78.5-64.3h64c38.8 0 71.2 27.6 78.5 64.3zM256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-272a40 40 0 1 1 0-80 40 40 0 1 1 0 80zm-88-40a88 88 0 1 0 176 0 88 88 0 1 0 -176 0z"
                        />
                    </svg>
                </ion-avatar>
            </ion-chip>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
