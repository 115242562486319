import { HttpClient } from '@angular/common/http'
import { Injectable, OnInit } from '@angular/core'
import { Params, Router } from '@angular/router'
import { Observable, map } from 'rxjs'
import { environment } from '../../../../shared/environments/environment'
import { MGEvent } from '../../../../shared/models/events/margaret-event.model'
import { Filters } from '../../../../shared/models/filters.model'
import { MGOrganization } from '../../../../shared/models/organization'
import { SearchInput } from '../../../../shared/models/search/search-input.model'
import { SearchParams } from '../../../../shared/models/search/search-params.model'
import { cleanNullOrUndefinedValues } from '../utils/util'

export interface MGSearchOptions {
    page: number
    city: string
    rad: number
    lat: number
    lon: number
    what: string
    startDate: string
    endDate: string
    category: string
    themes: number[]
    actualites: number[]
    criterias: number[]
    lastminute: boolean
}

@Injectable({ providedIn: 'root' })
export class SearchService implements OnInit {
    readonly initialFilters: Filters = {
        accessibility: false,
        dogs: false,
        family: false,
        free: false,
        indoor: false
    }

    categories: any[] = []

    query: string = null
    filters: Filters = this.initialFilters
    from: string
    to: string
    city: string
    gpsLat: number = null
    gpsLon: number = null
    rad: number = null
    dates: Date[] = []
    datesIonic: string[] = []

    fetched = false
    fetchingLocation = false
    around = false

    searchOptions: MGSearchOptions = {
        page: null,
        city: null,
        rad: null,
        lat: null,
        lon: null,
        what: null,
        startDate: null,
        endDate: null,
        category: null,
        themes: [],
        actualites: [],
        criterias: [],
        lastminute: null
    }
    eventsFounded: MGEvent[] = []
    eventsFoundedCount: number
    temporalEventSelectedId: number

    mapMarkers: any[] = []
    center: google.maps.LatLngLiteral
    specialPlaces: MGOrganization[] = []
    showMoreButton = true

    constructor(private router: Router, private httpClient: HttpClient) {}

    ngOnInit(): void {}

    searchOptionsReset() {
        this.searchOptions = {
            page: null,
            city: null,
            rad: null,
            lat: null,
            lon: null,
            what: null,
            startDate: null,
            endDate: null,
            category: null,
            themes: [],
            actualites: [],
            criterias: [],
            lastminute: null
        }
    }

    cleanSearchOptions(searchOptions) {
        searchOptions.city = this.city

        if (searchOptions.themes == undefined || searchOptions.themes[0] == undefined) {
            searchOptions.themes = []
        }
        if (this.temporalEventSelectedId) {
            if (searchOptions.actualites?.length > 0) {
                searchOptions.actualites[0] = this.temporalEventSelectedId
            } else {
                searchOptions.actualites = [this.temporalEventSelectedId]
            }
        }
        const clonedObject = { ...searchOptions }

        for (const [key, value] of Object.entries(clonedObject)) {
            if (
                value === undefined ||
                value === null ||
                (Array.isArray(value) && value.length === 0)
            ) {
                delete clonedObject[key]
            }
        }

        return clonedObject
    }

    buildQueryStringV4(cleanedOptions: MGSearchOptions) {
        if (Object.entries(cleanedOptions).length === 0) {
            return ''
        }

        let query = '?'
        const data = []
        for (const [key, value] of Object.entries(cleanedOptions)) {
            Array.isArray(value)
                ? data.push(`${key}=${JSON.stringify(value)}`)
                : data.push(`${key}=${value}`)
        }
        query += data.join('&')

        return query
    }

    getFeaturedEvents(seed: number, page?: number): Observable<MGEvent[]> {
        const seedParam = seed != 0 ? `&seed=${seed}` : ''
        const pagination = page ? `&page=${page}` : ''
        return this.httpClient
            .get<MGEvent[]>(`${environment.apiV3Url}/events?featured=true${pagination}${seedParam}`)
            .pipe(
                map((res: any) => {
                    return res
                })
            )
    }

    searchV4(page?: number) {
        this.mapMarkers = []
        if (page) {
            this.searchOptions.page = page
        } else {
            this.searchOptions.page = undefined
            this.eventsFounded = []
        }
        const cleanedOptions = this.cleanSearchOptions(this.searchOptions)
        this.query = this.buildQueryStringV4(cleanedOptions)
        return this.httpClient.get<MGEvent[]>(`${environment.apiV3Url}/events${this.query}`).pipe(
            map((res: any) => {
                this.eventsFoundedCount = res.total
                this.specialPlaces = res.specialPlaces
                this.eventsFounded = [...this.eventsFounded, ...res.events]
                // --------------------------    --------------------------
                this.eventsFounded.map((event: any) => {
                    event.address.position = {
                        lat: parseFloat(event.address.gpsLat),
                        lng: parseFloat(event.address.gpsLon)
                    }
                    event.icon = {
                        url: 'assets/icon/margaret.svg',
                        scaledSize: new google.maps.Size(32, 32)
                    }
                    this.mapMarkers.push(event)
                })

                const newBoundary = new google.maps.LatLngBounds()
                for (let index = 0; index < this.mapMarkers.length; index++) {
                    const position = this.mapMarkers[index].address.position
                    newBoundary.extend(position)
                }
                const lat = newBoundary.getCenter().lat()
                const lng = newBoundary.getCenter().lng()
                this.center = { lat, lng }
                // --------------------------    --------------------------
                this.router.navigate(['/events'])
                return res.events
            })
        )
    }

    buildSearchInputFromParams(params: Params): SearchInput {
        const keys = Object.keys(params)

        let input: SearchInput

        for (const key of keys) {
            if (['accessibility', 'dogs', 'family', 'free', 'indoor'].includes(key)) {
                input[key] = params[key] === 'true'
                continue
            }

            if (key === 'categories') {
                continue
            }

            input[key] = params[key]
        }
        return input
    }

    buildSearchQueryParams(input: SearchInput): SearchParams {
        let queryParams: SearchParams = {
            query: this.query,
            categories: input.categories?.join(','),
            from: this.from || null,
            to: this.to || null,
            city: this.city,
            rad: this.rad || null,
            // accessibility: input.accessibility,
            // dogs: input.dogs,
            // family: input.family,
            // free: input.free,
            // indoor: input.indoor,
            gps_lat: this.gpsLat || null,
            gps_lon: this.gpsLon || null,
            size: input.size,
            offset: input.offset
        }

        queryParams = cleanNullOrUndefinedValues(queryParams) as SearchParams

        return queryParams
    }

    buildQueryString(searchParams: Params) {
        if (!searchParams) {
            return ''
        }

        let query = '?'
        const data = []
        for (const [key, value] of Object.entries(searchParams)) {
            data.push(`${key}=${value}`)
        }
        query += data.join('&')

        return query
    }

    addCategorie(categoryId: number) {
        if (!this.categories) {
            this.categories = [categoryId]
            return
        }
        this.categories = [...this.categories, categoryId]
    }

    reset() {
        this.around = false
        this.city = undefined
        this.filters = this.initialFilters
        this.to = undefined
        this.from = undefined
        this.gpsLat = undefined
        this.gpsLon = undefined
        this.rad = undefined

        this.fetched = false

        this.categories = null

        // this.query = undefined
        // this.filters = this.initialFilters
        // this.from = undefined
        // this.to = undefined
        // this.city = undefined
        // this.gpsLat = null
        // this.gpsLon = null
        // this.rad = null
        // this.dates = []

        // this.fetched = false
        // this.fetchingLocation = false
        // this.around = false

        // this.router.navigate([], {
        //     queryParams: {
        //         rad: null,
        //         gps_lat: null,
        //         gps_lon: null
        //     },
        //     queryParamsHandling: 'merge'
        // })
    }

    // setSearchFromParams(params: Params) {
    //     if (params.rad) {
    //         this.rad = parseInt(params.rad)
    //     }

    //     if (params.from) {
    //         this.from = params.from
    //     }

    //     if (params.query) {
    //         this.query = params.query
    //     }

    //     if (params.to) {
    //         this.to = params.to
    //     }

    //     if (params.city) {
    //         this.city = params.city
    //         this.gpsLat = parseFloat(params.gps_lat)
    //         this.gpsLon = parseFloat(params.gps_lon)
    //     }

    //     if (!params.city && params.gps_lat && params.gps_lon) {
    //         this.around = true
    //         this.gpsLat = parseFloat(params.gps_lat)
    //         this.gpsLon = parseFloat(params.gps_lon)
    //     }

    //     // if (params.accessibility !== null) {
    //     //     this.filters = {
    //     //         accessibility: params.accessibility,
    //     //         dogs: params.dogs,
    //     //         family: params.family,
    //     //         free: params.free,
    //     //         indoor: params.indoor
    //     //     }
    //     // }
    // }

    removeCategorie(id: number) {
        const categories = this.categories
        const index = categories.indexOf(id)
        categories.splice(index, 1)

        if (categories.length === 0) {
            this.categories = null
            return
        }

        this.categories = categories
    }

    clearCategories() {
        this.categories = []
    }

    search(params: SearchInput, from?: string, blank?: boolean) {
        const queryParams = this.buildSearchQueryParams(params)
        let route = '/events'
        from == 'home' ? (route = '/') : null
        this.router.navigate([route], {
            queryParams
        })
        return queryParams
    }
}
