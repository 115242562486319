<ion-header>
    <ion-toolbar color="primary">
        <ion-title>Margaret présente</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onClose($event)">Fermer</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content
    [style]="
        'position: relative; --background: url(' + ad.picture + '); background-position: center;'
    "
    (click)="onClick($event, 'open')"
>
    <!-- <ion-button fill="solid" shape="round" (click)="onClick($event, 'close')">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
    </ion-button> -->
</ion-content>
