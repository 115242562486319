import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, tap } from 'rxjs/operators'
import { environment } from '../../../../shared/environments/environment'
import { FavoriteCreateData } from '../../../../shared/models/favorites/favorite-create-data.model'
import { FavoriteData } from '../../../../shared/models/favorites/favorite-data.model'
import { FavoriteRemoveData } from '../../../../shared/models/favorites/favorite-remote-data.model'
import { ToastService } from './toast.service'

/**
 * in the version 2.1, the Agenda model represents favorites
 *
 */
@Injectable({ providedIn: 'root' })
export class FavoriteService {
    favoriteEvents = []

    constructor(private httpClient: HttpClient, private toastService: ToastService) {}

    // Copied from eventSrv (to avoid circulary dep)
    getEvent(id: string) {
        return this.httpClient.get(`${environment.apiV3Url}/events/${id}`).pipe(
            map((event: any) => {
                return event.event
            })
        )
    }

    isEventFavorite(eventUniqueId: string): boolean {
        const found = this.favoriteEvents.find(event => {
            return event.event.uniqueid == eventUniqueId
        })
        return found ? true : false
    }

    getFavorites() {
        return this.httpClient
            .get<FavoriteData[]>(`${environment.apiV3Url}/me/bookmarks/events`)
            .pipe(
                map(favoriteEvents => {
                    this.favoriteEvents = favoriteEvents
                })
            )
    }

    createFavorite(eventUniqueId: string) {
        return this.httpClient
            .post<FavoriteCreateData>(
                `${environment.apiV3Url}/me/bookmarks/events/${eventUniqueId}`,
                {
                    eventUniqueId: eventUniqueId
                }
            )
            .pipe(
                tap(newFavorite => {
                    this.favoriteEvents.push(newFavorite)
                    // this.addFavoriteToCurrentFavorites(newFavorite.id, newFavorite.event_uniqueid)
                })
            )
            .subscribe(
                data => {
                    this.toastService.create("L'évènement a bien été ajouté à vos favoris.", 3000)
                },
                error => {
                    this.toastService.createError(
                        "Une erreur s'est produite lors de l'ajout à vos favoris.",
                        3000
                    )
                }
            )
    }

    removeFavorite(eventUniqueId: string) {
        return this.httpClient
            .delete<FavoriteRemoveData>(
                `${environment.apiV3Url}/me/bookmarks/events/${eventUniqueId}`
            )
            .subscribe(
                data => {
                    const idx = this.favoriteEvents.findIndex(event => {
                        return event.uniqueId == eventUniqueId
                    })
                    this.favoriteEvents.splice(idx, 1)
                    this.toastService.create(
                        "L'évènement a bien été supprimé de vos favoris.",
                        3000
                    )
                },
                error => {
                    this.toastService.createError(
                        "Une erreur s'est produite lors de la suppression de vos favoris",
                        3000
                    )
                }
            )
    }
}
